import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

function LinkSection() {
  return (
    <Stack spacing={0.1}>
      <Typography color="text.hint" variant="caption" component={Link} to="/use" target="_blank">Acceptable Use</Typography>
      <Typography color="text.hint" variant="caption" component={Link} to="/privacy" target="_blank">Privacy Policy</Typography>
      <Typography color="text.hint" variant="caption" component={Link} to="/terms" target="_blank">Terms & Conditions</Typography>
    </Stack>
  );
}

export default LinkSection;
