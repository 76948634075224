import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function NavigatorSectionMobile({ route, navItems }) {
  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    const activeNavItemIndex = navItems.findIndex((item) => item.link === route);
    if (activeNavItemIndex !== -1) {
      setValue(activeNavItemIndex);
    }
  }, [route, navItems, setValue]);

  return (
    <Box sx={{
      width: '100%', position: 'fixed', bottom: 0, left: 0, right: 0,
    }}
    >
      <BottomNavigation
        showLabels
        sx={{ height: '70px' }}
        value={value}
        onChange={(event, newValue) => (setValue(newValue))}
      >
        {navItems.map((item) => (
          <BottomNavigationAction
            key={item.link}
            component={Link}
            to={item.link}
            label={route === item.link ? (
              <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>{item.title}</Typography>
            ) : (
              <Typography sx={{ fontSize: 12, fontWeight: item.link === '/referrals' && 'bold' }}>{item.title}</Typography>
            )}
            icon={route === item.link ? item.activeIcon : item.inactiveIcon}
            sx={{
              color: item.link === '/referrals' && 'secondary.main',
              fontWeight: item.link === '/referrals' && 'bold',
              '&.Mui-selected': {
                color: item.link === '/referrals' ? 'secondary.main' : 'text.primary',
                fontWeight: 'bold',
              },
            }}
          />
        ))}
      </BottomNavigation>
    </Box>
  );
}

NavigatorSectionMobile.propTypes = {
  route: PropTypes.string.isRequired,
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      activeIcon: PropTypes.element.isRequired,
      inactiveIcon: PropTypes.element.isRequired,
    }),
  ).isRequired,
};

export default NavigatorSectionMobile;
