import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import Pagination from '@mui/material/Pagination';

import isXsScreen from '../../../common/utils/isXsScreen';
import CustomPagination from './Pagination';
import { nonPreferredImageDomains, placeholderThumbnail } from '../../../common/utils/ImageThumbnailLists';

function ArticleMedia({ sources }) {
  const isXsScreenValue = isXsScreen();
  const [activeIndex, setActiveIndex] = useState(0);
  const [isMouseOver, setIsMouseOver] = useState(false);

  const isWatermarked = (url) => {
    if (url) {
      const domain = new URL(url).hostname;
      return nonPreferredImageDomains.includes(domain);
    }
    return true;
  };

  const validSources = sources.filter((source) => !isWatermarked(source.source.link));

  useEffect(() => {
    setActiveIndex(0); // Reset index when sources change
  }, [sources]);

  const handleNext = () => {
    let nextIndex;
    if (activeIndex < 4) {
      nextIndex = (activeIndex + 1) % validSources.length;
    } else {
      nextIndex = 0;
    }

    setActiveIndex(nextIndex);
  };

  const handlePrev = () => {
    let prevIndex;
    if (activeIndex > 1) {
      prevIndex = (activeIndex - 1 + validSources.length) % validSources.length;
    } else {
      prevIndex = 4;
    }
    setActiveIndex(prevIndex);
  };

  return (
    <Box onMouseEnter={() => setIsMouseOver(true)} onMouseLeave={() => setIsMouseOver(false)}>
      <Card sx={{
        maxWidth: isXsScreenValue ? '100%' : '640px',
        height: isXsScreenValue ? '200px' : '350px',
        borderRadius: '15px',
        position: 'relative',
      }}
      >
        {(validSources.length > 0 && isMouseOver) && (
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              padding: '0 10px',
              zIndex: 1,
            }}
          >
            <ChevronLeftIcon
              onClick={handlePrev}
              sx={{
                fontSize: '50px',
                cursor: 'pointer',
                color: 'rgba(255, 255, 255, 0.7)',
              }}
            />
            <ChevronRightIcon
              onClick={handleNext}
              sx={{
                fontSize: '50px',
                cursor: 'pointer',
                color: 'rgba(255, 255, 255, 0.7)',
              }}
            />
          </Box>
        )}
        <CardMedia
          alt="article-image"
          image={validSources[activeIndex]?.source.image_url || placeholderThumbnail}
          elevation={3}
          component={Link}
          target="_blank"
          to={validSources[activeIndex]?.source.image_url}
          sx={{
            height: 0,
            paddingTop: '60%',
            backgroundPosition: 'center',
            cursor: 'pointer',
            objectFit: 'contain',
            borderRadius: '15px',
          }}
        />
        {(validSources.length > 0 && isMouseOver) && (
          <Box
            sx={{
              position: 'absolute',
              bottom: '8px',
              left: '50%',
              transform: 'translateX(-50%)',
              display: 'flex',
              gap: '5px',
              zIndex: 1,
            }}
          >
            <CustomPagination
              pageCount={Math.min(validSources.length, 5)} // Max 5 dots
              activeIndex={activeIndex}
              onPageChange={(index) => setActiveIndex(index)}
            />
            {/* <Pagination
              count={Math.min(validSources.length, 5)} // Max 5 dots
              page={activeIndex + 1}
              size="small"
              color="primary"
              shape="rounded"
              hidePrevButton
              hideNextButton
              hideFirstButton
              hideLastButton
              sx={{
                '& .MuiPaginationItem-root': {
                  borderRadius: '50%',
                  backgroundColor: 'rgba(255, 255, 255, 0.5)',
                  width: '8px',
                  height: '8px',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                  },
                },
                '& .Mui-selected': {
                  backgroundColor: 'white',
                },
                '& .MuiPaginationItem-sizeSmall': {
                  minWidth: '8px',
                  color: 'transparent', // Hide numbers
                },
              }}
            /> */}
          </Box>
        )}
      </Card>
    </Box>
  );
}

ArticleMedia.propTypes = {
  sources: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    link: PropTypes.string,
    image_url: PropTypes.string,
    pubDate: PropTypes.string,
    org: PropTypes.shape({
      paywall: PropTypes.bool.isRequired,
      annoyingAds: PropTypes.bool.isRequired,
      name: PropTypes.string.isRequired,
      image_url: PropTypes.string.isRequired,
    }),
  })).isRequired,
};

export default ArticleMedia;
