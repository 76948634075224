import React, {
  createContext, useState, useEffect, useContext, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { supabase } from '../utils/supabaseClient';

const FeedContext = createContext();

export function FeedProvider({ children }) {
  const pageSize = 6;
  const [pageNumber, setPageNumber] = useState(0);
  const [loading, setLoading] = useState(true);
  const [fetchError, setFetchError] = useState(false);
  const [articles, setArticles] = useState([]);
  const [filter, setFilter] = useState('all');
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    setArticles([]);
  }, [filter]);

  useEffect(() => {
    const fetchArticles = async () => {
      setLoading(true);
      setFetchError(false);

      let data;
      let error;

      if (filter !== 'all') {
        ({ data, error } = await supabase
          .from('articles')
          .select('id, created_at, pnyx_category, title, text, summary, sources:source_index(source:sources(id, published_at, link, title, image_url, org:organizations(org_id, name, image_url, link, paywall, annoying_ads)))')
          .filter('issue', 'is', null)
          .order('created_at', { ascending: false, nullsFirst: false })
          .range(pageNumber * pageSize, (pageNumber + 1) * pageSize - 1)
          .eq('pnyx_category', filter));
      } else {
        ({ data, error } = await supabase
          .from('articles')
          .select('id, created_at, pnyx_category, title, text, summary, sources:source_index(source:sources(id, published_at, link, title, image_url, org:organizations(org_id, name, image_url, link, paywall, annoying_ads)))')
          .filter('issue', 'is', null)
          .order('created_at', { ascending: false, nullsFirst: false })
          .range(pageNumber * pageSize, (pageNumber + 1) * pageSize - 1));
      }

      if (error) {
        setFetchError(true);
      } else {
        setArticles((prevData) => [...prevData, ...data]);
      }
      setLoading(false);
    };

    fetchArticles();
  }, [filter, pageNumber]);

  const filterResults = (category) => {
    setFilter(category);
  };

  const value = useMemo(() => ({
    articles,
    loading,
    fetchError,
    setPageNumber,
    setFilter,
    scrollPosition,
    setScrollPosition,
    filter,
    filterResults,
  }), [articles, loading, fetchError, setPageNumber,
    scrollPosition, setScrollPosition, filter]);

  return (
    <FeedContext.Provider value={value}>
      {children}
    </FeedContext.Provider>
  );
}

FeedProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useFeed = () => useContext(FeedContext);
