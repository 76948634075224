import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

/* eslint-disable max-len */

function AcceptableUse() {
  return (
    <Paper sx={{ m: 4 }}>
      <List sx={{ width: '100%' }}>
        <ListItem>
          <Typography variant="h4">Pnyx Acceptable Use Policy</Typography>
        </ListItem>
        <ListItem>
          <Typography variant="h6">Last Modified: April 7th, 2023</Typography>
        </ListItem>
        <ListItem alignItems="flex-start">
          <ListItemText
            primary={<Typography sx={{ textDecoration: 'underline', fontWeight: 'bold' }}>Introduction</Typography>}
            secondary={(
              <>
                <br />
                <Typography>
                  The Pnyx website, application and proprietary software platform that connects individuals and provides a centralized platform for open debate (the &quot;Service&quot;) is owned and operated by Pnyx Inc. (&quot;Pnyx&quot;) and made available to registered users who have an account on the Service.
                </Typography>
                <br />
                <Typography>
                  This Acceptable Use Policy (this AUP) governs your access to and use of the Service and is expressly incorporated by reference into the Pnyx Terms of Use. Pnyx reserves the right to amend, alter, or modify your conduct requirements as set forth in this AUP at any time. By accessing the Service, you accept and agree to be bound and abide by this AUP. If you do not want to agree to this AUP, you must not access or use the Service.
                </Typography>
              </>
            )}
          />
        </ListItem>
        <ListItem alignItems="flex-start">
          <ListItemText
            primary={<Typography sx={{ textDecoration: 'underline', fontWeight: 'bold' }}>Prohibited Uses</Typography>}
            secondary={(
              <>
                <br />
                <Typography>
                  You may use the Service only for lawful purposes and in accordance with this AUP. You agree not to use the Service:
                </Typography>
                <br />
                <Typography component="ul">
                  <li>
                    In any way that violates any applicable federal, state, local, or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from the US or other countries).
                  </li>
                </Typography>
                <br />
                <Typography component="ul">
                  <li>
                    For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information, or otherwise.
                  </li>
                </Typography>
                <br />
                <Typography component="ul">
                  <li>
                    To transmit, or procure the sending of, any advertising or promotional material, including any &quot;junk mail,&quot; &quot;chain letter,&quot; &quot;spam,&quot; or any other similar solicitation.
                  </li>
                </Typography>
                <br />
                <Typography component="ul">
                  <li>
                    To impersonate or attempt to impersonate Pnyx, a Pnyx employee, another user, or any other person or entity (including, without limitation, by using e-mail addresses or screen names associated with any of the foregoing).
                  </li>
                </Typography>
                <br />
                <Typography component="ul">
                  <li>
                    To engage in any other conduct that restricts or inhibits anyone&apos;s use or enjoyment of the Service, or which, as determined by us, may harm Pnyx or users of the Service or expose them to liability.
                  </li>
                </Typography>
                <br />
                <Typography>
                  Additionally, you agree not to:
                </Typography>
                <br />
                <Typography component="ul">
                  <li>
                    Use the Service in any manner that could disable, overburden, damage, or impair the Service or interfere with any other party&apos;s use of the Service, including their ability to engage in real-time activities through the Service.
                  </li>
                </Typography>
                <br />
                <Typography component="ul">
                  <li>
                    Use any robot, spider, or other automatic device, process, or means to access the Service for any purpose, including monitoring or copying any Service traffic or resources available on the Service.
                  </li>
                </Typography>
                <br />
                <Typography component="ul">
                  <li>
                    Use any manual process to monitor or copy any Service traffic or resources available on the Service or for any other unauthorized purpose without our prior written consent.
                  </li>
                </Typography>
                <br />
                <Typography component="ul">
                  <li>
                    Use any device, software, or routine that interferes with the proper working of the Service.
                  </li>
                </Typography>
                <br />
                <Typography component="ul">
                  <li>
                    Introduce any viruses, trojan horses, worms, logic bombs, or other software or material that is malicious or technologically harmful.
                  </li>
                </Typography>
                <br />
                <Typography component="ul">
                  <li>
                    Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Service or any server, computer, database, or other resource or element connected to the Service.
                  </li>
                </Typography>
                <br />
                <Typography component="ul">
                  <li>
                    Violate, attempt to violate, or knowingly facilitate the violation of the security orintegrity of the Service.
                  </li>
                </Typography>
                <br />
                <Typography component="ul">
                  <li>
                    Otherwise attempt to interfere with the proper working of the Service.
                  </li>
                </Typography>
              </>
            )}
          />
        </ListItem>
        <ListItem alignItems="flex-start">
          <ListItemText
            primary={<Typography sx={{ textDecoration: 'underline', fontWeight: 'bold' }}>Content Standards</Typography>}
            secondary={(
              <>
                <br />
                <Typography>
                  You agree not to use the Service to send, knowingly receive, upload, download, use, or re-use any material which:
                </Typography>
                <br />
                <Typography component="ul">
                  <li>
                    Contains any material that is defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful, inflammatory, or otherwise objectionable.
                  </li>
                </Typography>
                <br />
                <Typography component="ul">
                  <li>
                    Promotes sexually explicit or pornographic material and violence.
                  </li>
                </Typography>
                <br />
                <Typography component="ul">
                  <li>
                    Infringes any patent, trademark, trade secret, copyright, or other intellectual property or other rights of any other person.
                  </li>
                </Typography>
                <br />
                <Typography component="ul">
                  <li>
                    Violates the legal rights (including the rights of publicity and privacy) of others or contains any material that could give rise to any civil or criminal liability under applicable laws or regulations.
                  </li>
                </Typography>
                <br />
                <Typography component="ul">
                  <li>
                    Promotes any illegal activity or advocates, promotes, or assists any unlawful act.
                  </li>
                </Typography>
                <br />
                <Typography component="ul">
                  <li>
                    Impersonates any person or misrepresents your identity or affiliation with any person or organization.
                  </li>
                </Typography>
                <br />
                <Typography component="ul">
                  <li>
                    Involves commercial activities or sales, such as contests, sweepstakes, and other sales promotions, barter, or advertising.
                  </li>
                </Typography>
                <br />
                <Typography component="ul">
                  <li>
                    Gives the impression that they emanate from or are endorsed by us or any other person or entity, if this is not the case.
                  </li>
                </Typography>
              </>

            )}
          />
        </ListItem>
        <ListItem alignItems="flex-start">
          <ListItemText
            primary={<Typography sx={{ textDecoration: 'underline', fontWeight: 'bold' }}>Monitoring and Enforcement</Typography>}
            secondary={(
              <>
                <br />
                <Typography>
                  Pnyx, in its sole discretion, will determine whether your conduct is in compliance with this AUP. We have the right to:
                </Typography>
                <br />
                <Typography component="ul">
                  <li>
                    Monitor your use of the Service for any purpose in our sole discretion and as we see fit.
                  </li>
                </Typography>
                <br />
                <Typography component="ul">
                  <li>
                    Take any action we deem necessary or appropriate in our sole discretion if we believe a user&apos;s conduct violates this AUP, infringes any intellectual property right or other right of any person or entity, threatens the personal safety of users of the Service or the public, or could create liability for Pnyx.
                  </li>
                </Typography>
                <br />
                <Typography component="ul">
                  <li>
                    Disclose your identity or other information about you to any third party who claims that material posted by you violates their rights, including their intellectual property rights or their right to privacy.
                  </li>
                </Typography>
                <br />
                <Typography component="ul">
                  <li>
                    Take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or unauthorized use of the Service.
                  </li>
                </Typography>
                <br />
                <Typography component="ul">
                  <li>
                    Terminate or suspend your access to all or part of the Service for any or no reason, including without limitation, any violation of this AUP.
                  </li>
                </Typography>
                <br />
                <Typography>
                  Without limiting the foregoing, we have the right to fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone who accesses or uses the Service. YOU WAIVE AND HOLD HARMLESS PNYX AND ITS AFFILIATES, LICENSEES AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY PNYX AND ANY OF THE FOREGOING PARTIES DURING, OR TAKEN AS A CONSEQUENCE OF, INVESTIGATIONS BY EITHER PNYX OR LAW ENFORCEMENT AUTHORITIES.
                </Typography>
              </>

            )}
          />
        </ListItem>
      </List>
    </Paper>
  );
}

export default AcceptableUse;
