// React
import React from 'react';

// External Components
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import isXsScreen from '../../common/utils/isXsScreen';
import isXlScreen from '../../common/utils/isXlScreen';
import JoinBetaButton from '../../common/components/JoinBetaButton';
import isLScreen from '../../common/utils/isLScreen';
import isMScreen from '../../common/utils/isMScreen';

/* eslint-disable no-nested-ternary */

function Landing() {
  const isXs = isXsScreen();
  const isXl = isXlScreen();
  const isL = isLScreen();
  const isM = isMScreen();

  return (
    <Stack
      alignItems="center"
      spacing={isXs ? 2 : 6}
      divider={<Divider flexItem />}
      sx={{ mx: isXs ? 1 : 'auto' }}
    >
      <Stack
        direction={isXs ? 'column' : 'row'}
        justifyContent="center"
        alignItems={isXs ? 'center' : 'start'}
        spacing={6}
        sx={{ px: 6, pt: 0, minHeight: 'calc(100vh - 176px)' }}
      >
        <Stack alignItems="center" direction="row" spacing={2} sx={{ pt: 1.25 }}>
          <img
            src="/assets/landing/article-card.png"
            alt="screenshot of article card"
            width={isXs ? 200 : 300}
          />
          {isL && (
            <img
              src="/assets/landing/full-article.png"
              alt="screenshot of full article"
              width={isXs ? 200 : 300}
            />
          )}
          {isM && (
            <img
              src="/assets/landing/sources.png"
              alt="screenshot of sources"
              width={isXs ? 200 : 300}
            />
          )}
        </Stack>
        <Box>
          <Typography variant={isXs ? 'h4' : 'h2'} fontWeight="bold" sx={{ textAlign: isXs ? 'left' : 'left' }}>An LLM-powered news platform aggregating top sources from the web</Typography>
          <Typography variant={isXs ? 'h6' : 'h4'} fontWeight="bold" color="text.hint">
            <br />
            In today&apos;s media environment, staying informed can feel overwhelming.
          </Typography>
          <Typography variant={isXs ? 'h6' : isXl ? 'h4' : 'h5'} color="text.hint">
            <br />
            Pnyx uses LLMs to aggregate information from diverse sources—primary,
            independent, and mainstream—delivering insights and varied perspectives fast.
          </Typography>
          <JoinBetaButton sx={{ width: isXs ? '100%' : '95%' }} />
        </Box>
      </Stack>
      <Stack
        direction={isXs ? 'column' : 'row'}
        alignSelf="start"
        justifyContent={isXs ? 'center' : 'space-around'}
        alignItems="center"
        spacing={6}
        sx={{ px: isXs ? 6 : 12, minHeight: 'calc(100vh - 32px)', width: '100%' }}
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          spacing={isXs ? 2 : 10}
        >
          <img
            src="/assets/landing/sources-graphic.png"
            alt="graphic of reports, podcasts, videos, and more going to brain"
          />
          <Stack
            justifyContent="center"
            alignItems="start"
            sx={{ maxWidth: 856 }}
          >
            <Typography variant={isXs ? 'h4' : 'h2'} fontWeight="bold">Pulling from all types of sources</Typography>
            <Typography variant={isXs ? 'h6' : 'h4'} color="text.hint">
              <br />
              We pull from reports, podcasts, videos, and more,
              giving you a broad overview while deduplicating
              news items that cover the same stories.
            </Typography>
          </Stack>
        </Stack>
        <img
          src="/assets/landing/sources.png"
          alt="screenshot of sources"
          width={isXs && 200}
        />
      </Stack>

      <Stack
        direction={isXs ? 'column-reverse' : 'row'}
        alignSelf="end"
        justifyContent={isXs ? 'center' : 'space-around'}
        alignItems="center"
        spacing={6}
        sx={{ px: isXs ? 6 : 12, minHeight: 'calc(100vh - 32px)', width: '100%' }}
      >
        <img
          src="/assets/landing/article-card.png"
          alt="screenshot of article card"
          width={isXs && 200}
        />
        <Stack
          justifyContent="center"
          alignItems="center"
          spacing={isXs ? 2 : 10}
          sx={{ maxWidth: 860 }}
        >
          <img
            src="/assets/landing/direct-graphic.png"
            alt="graphic of information directly going to brain"
          />
          <Stack>
            <Typography variant={isXs ? 'h4' : 'h2'} fontWeight="bold">Free of clickbait and popups</Typography>
            <Typography variant={isXs ? 'h6' : 'h4'} color="text.hint">
              <br />
              LLMs rewrite article titles,
              summaries and content so you can avoid annoying clickbait and popups.
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      <Stack
        spacing={4}
        justifyContent="center"
        alignItems="center"
        sx={{ py: 4 }}
      >
        <Typography variant={isXs ? 'h4' : 'h2'} fontWeight="bold" sx={{ textAlign: isXs ? 'left' : 'center' }}>Check out our beta!</Typography>
        <JoinBetaButton />
      </Stack>
    </Stack>
  );
}

export default Landing;
