import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CopyrightSection from './CopyrightSection';
import FilterBar from './Header';
import LinkSection from './LinkSection';
import LogoSection from './LogoSection';
import LogoSectionMobile from './LogoSectionMobile';
import NavigatorSection from './NavigatorSection';
import NavigatorSectionCollapsed from './NavigatorSectionCollapsed';
import NavigatorSectionMobile from './NavigatorSectionMobile';
import SocialSection from './SocialSection';
import isXsScreen from '../../utils/isXsScreen';

/* eslint-disable react/jsx-props-no-spreading */

function AppLayout({ children, filterBar }) {
  const isXs = isXsScreen();
  const location = useLocation();

  const [isFilterBarVisible, setIsFilterBarVisible] = useState(true);
  const [open, setOpen] = useState(true);

  const drawerWidth = open ? 235 : 70;

  const toggleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (isXs && currentScrollY > lastScrollY && currentScrollY > 0) {
        setIsFilterBarVisible(false);
      } else {
        setIsFilterBarVisible(true);
      }

      lastScrollY = currentScrollY;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isXs]);

  const navItems = [
    // {
    //   title: 'News Feed',
    //   link: '/feed',
    //   activeIcon: <ArticleIcon />,
    //   inactiveIcon: <ArticleOutlinedIcon />,
    // },
    // {
    //   title: 'Earn Swag!',
    //   link: '/referrals',
    //   activeIcon: <RedeemIcon />,
    //   inactiveIcon: <RedeemIcon />,
    // },
  ];

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexDirection: isXs ? 'column' : 'row',
      flexGrow: 1,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: isXs ? theme.spacing(1) : theme.spacing(0),
      overflowY: 'auto',
      marginTop: filterBar && location.pathname === '/feed' ? '80px' : '0px',
    },
    expandButtonContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
    filterBar: {
      position: 'fixed',
      top: 0,
      width: '100%',
      zIndex: 1,
      backgroundColor: '#121212',
      transition: 'transform 0.3s ease-in-out',
      transform: isFilterBarVisible ? 'translateY(0)' : 'translateY(-100%)',
    },
  }));

  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {!isXs
      && (
      <Drawer
        className={classes.drawer}
        variant="permanent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        {open ? (
          <>
            <LogoSection toggleDrawer={toggleDrawer} />
            <NavigatorSection
              route={location.pathname}
              navItems={navItems}
            />
            <Stack spacing={1} sx={{ mt: 'auto', p: 2 }}>
              <LinkSection />
              <SocialSection />
              <CopyrightSection />
            </Stack>
          </>
        ) : (
          <>
            <LogoSectionMobile toggleDrawer={toggleDrawer} />
            <NavigatorSectionCollapsed
              route={location.pathname}
              navItems={navItems}
            />
            <Box className={classes.expandButtonContainer}>
              <IconButton onClick={toggleDrawer} className={classes.expandButton} sx={{ my: 1 }}>
                <ArrowForwardIcon />
              </IconButton>
            </Box>
          </>
        )}
      </Drawer>
      )}
      <Box className={classes.content}>
        {filterBar && (
          <Box className={classes.filterBar}>
            <FilterBar route={location.pathname} />
          </Box>
        )}
        {children}
      </Box>
      {isXs && (
        <NavigatorSectionMobile route={location.pathname} navItems={navItems} />
      )}
    </Box>
  );
}

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
  filterBar: PropTypes.bool,
};

AppLayout.defaultProps = {
  filterBar: false,
};

export default AppLayout;
