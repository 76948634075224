// React
import React from 'react';

// External Components
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

/* eslint-disable max-len */

function PrivacyPolicy() {
  return (
    <Paper sx={{ m: 4 }}>
      <List sx={{ width: '100%' }}>
        <ListItem>
          <Typography variant="h4">Pnyx Privacy Policy</Typography>
        </ListItem>
        <ListItem>
          <Typography variant="h6">Last Modified: April 7th, 2023</Typography>
        </ListItem>
        <ListItem alignItems="flex-start">
          <ListItemText
            primary={<Typography sx={{ textDecoration: 'underline', fontWeight: 'bold' }}>Introduction</Typography>}
            secondary={(
              <>
                <br />
                <Typography>
                  The Pnyx website, application, and proprietary software platform that connects individuals and provides a centralized platform for open debate (the &quote;Service&quote;) is owned and operated by Pnyx Inc. (&quote;Pnyx&quote;) and made available to registered users who have an Account on the Service. This Privacy Policy describes:
                </Typography>
                <br />
                <Typography component="ul">
                  <li>
                    Why we collect personal information
                  </li>
                </Typography>
                <br />
                <Typography component="ul">
                  <li>
                    What personal information we collect and when it is collected
                  </li>
                </Typography>
                <br />
                <Typography component="ul">
                  <li>
                    How your information is used and protected
                  </li>
                </Typography>
                <br />
                <Typography component="ul">
                  <li>
                    When and with whom your information is shared
                  </li>
                </Typography>
                <br />
                <Typography component="ul">
                  <li>
                    Your choices regarding your personal information
                  </li>
                </Typography>
                <br />
                <Typography>
                  We encourage you to read this Privacy Policy, our Acceptable Use Policy, and our Terms of Use carefully. Capitalized terms used but not defined in this Privacy Policy have the meaning given to them in the Terms of Use. We will post notices of all changes that materially affect the way in which your personally identifiable information may be used or shared in updates to our Privacy Policy.
                </Typography>
                <br />
                <Typography>
                  This policy does not apply to other service providers, advertising systems, networks, or websites that Pnyx has a relationship with or to other companies or individuals that Pnyx does not own, employ, manage, or control.
                </Typography>
                <br />
                <Typography>
                  If you have any questions about this Privacy Policy, please feel free to contact us through our Service or write to us at Pnyx Inc., info@pnyx.io.
                </Typography>
              </>
         )}
          />
        </ListItem>
        <ListItem alignItems="flex-start">
          <ListItemText
            primary={<Typography sx={{ textDecoration: 'underline', fontWeight: 'bold' }}>Information We Collect</Typography>}
            secondary={(
              <div>
                <br />
                <div> The information we learn from users helps us not only provide the Service but also to personalize and continually improve each user’s experience with the Service. Here are the types of information we gather:</div>
                <br />
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Information You Give Us</Typography>
                <br />
                <div> Personal Information: When you create an account on the Pnyx platform, we may ask you for certain limited personally identifiable information such as your name, email address and phone number, which we will use to contact or identify you. Most of this information is recommended but not required to be shared with us and can be adjusted or limited depending upon your preferences. You can choose not to provide us with any or all of the information we specify or request, but then you may not be able to register with us or to take advantage of some or all of our features.</div>
                <br />
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Automatic Information Collected</Typography>
                <br />
                <div> To perform the Service, we also collect information through cookies and other automated means. Information of this sort includes:</div>
                <br />
                <div> Technical information about your browser and mobile device: This information is used in the aggregate to help us optimize the Service for common browsers and devices.</div>
                <br />
                <div>Usage information, such as the features and emails from Pnyx that you interact with: We collect and use this behavioral information and may use it in anonymized and aggregate forms to generate statistics about how the Services are being used but the information is not shared in any form that could be used to identify you personally. Please see “How we use and share the information we collect,” below, for further information and choices about sharing information with third parties.</div>
                <br />
                <div>
                  IP address and log file information, cookies, tokens and device identifiers: These are alphanumeric identifiers that help us to distinguish between unique browsers and devices in order to avoid showing you the same information twice, keep you logged into Pnyx, prevent duplicate actions, prevent duplicate coupon redemptions and improve your experience. The information we collect from cookies may include your IP address, browser and device characteristics, referring URLs, and a record of your interactions with our Service. By using the Service, you are consenting to such use of cookies. Most Web browsers can be set to inform you when a cookie has been sent to you and provide you with the opportunity to refuse that cookie. We will respect your choices relating to on-line tracking, whether you choose to reject individual cookies or set your web browser to reject cookies and other tracking technology. However, refusing a cookie may, in some cases, preclude you from using, or negatively impact the display or function of, the Service or certain areas or features of the Service.
                </div>
                <br />
                <div>Crashes and error reports: If you encounter a crash or error while using our Service, we may generate a crash report that includes technical, usage and, if you are logged in, your account information so that we can diagnose and potentially prevent the problem in the future.</div>
              </div>
        )}
          />
        </ListItem>
        <ListItem alignItems="flex-start">
          <ListItemText
            primary={<Typography sx={{ textDecoration: 'underline', fontWeight: 'bold' }}>How We Use and Share the Information We Collect</Typography>}
            secondary={(
              <>
                <br />
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Use</Typography>
                <br />
                <Typography>
                  We use the information we collect for things like:
                </Typography>
                <br />
                <Typography component="ul">
                  <li>
                    Establishing and managing your account and providing the Service
                  </li>
                </Typography>
                <br />
                <Typography component="ul">
                  <li>
                    Conducting research and analysis
                  </li>
                </Typography>
                <br />
                <Typography component="ul">
                  <li>
                    Identifying you on our platform and communicating things like surveys and offers
                  </li>
                </Typography>
                <br />
                <Typography component="ul">
                  <li>
                    Operating, evaluating, and improving our business model
                  </li>
                </Typography>
                <br />
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Share</Typography>
                <br />
                <Typography>
                  As a User, your Content will be made available with all other registered Users of the Service, as will certain identifiers you provide that we use to create your profile handle and enable you to communicate with other Users. You will not have the opportunity to remove or delete your Content automatically once it is &quote;live&quote; on the Service (which is generally immediately after you record or post it) and should think carefully before expressing your opinions and arguments.
                </Typography>
                <br />
                <Typography>
                  Please remember that if you provide personal information in your Content and choose to make the Content publicly available, then that information becomes publicly available. Individuals reading, listening, or viewing such information may use or disclose it to other individuals or entities without our control and without your knowledge, and search engines may index that information. We therefore urge you to think carefully about including any specific information you may deem private in content that is shared or publicly available.
                </Typography>
                <br />
                <Typography>
                  We will not share your personal information or user data or images except to perform the Services as described herein or unless you authorize us to.
                </Typography>
                <br />
                <Typography>
                  In the normal course of business, Pnyx may share your information with individuals (such as employees, contractors, and lawyers) and companies (such as consultants and service providers, e.g., a push notification delivery service or a data analytics firm) to perform tasks on our behalf and may need to share certain information, including images that could be used to identify individuals personally, with them in order to provide improved products or services to our users. However, these individuals and companies do not have any right to use the information we share with them beyond what is necessary to assist us in providing the Service to you as described in this Privacy Policy.
                </Typography>
                <br />
                <Typography>
                  Sometimes we may be required to share your information in response to a regulation, court order, or subpoena. We may also share information when we believe it is necessary to comply with the law or to respond to a government request or when we believe disclosure is necessary or appropriate to protect the rights, property, or safety of Pnyx, our customers, or others; to prevent harm or loss; or in connection with an investigation of suspected or actual unlawful activity.
                </Typography>
                <br />
                <Typography>
                  We may also share your information in the event of a corporate sale, merger, acquisition, dissolution, or similar event.
                </Typography>
              </>
         )}
          />
        </ListItem>
        <ListItem alignItems="flex-start">
          <ListItemText
            primary={<Typography sx={{ textDecoration: 'underline', fontWeight: 'bold' }}>How We Store and Protect the Information We Collect</Typography>}
            secondary={(
              <>
                <br />
                <Typography>Pnyx uses reasonable security measures to store and protect the information under our control and appropriately limit access to it. However, we cannot ensure or warrant the security of any information you transmit to us and you do so at your own risk.</Typography>
                <br />
                <Typography>We use a variety of information security measures to protect your online transactions with us. The Service uses encryption technology, such as Secure Sockets Layer (SSL), to protect your sensitive personal information during data transport.</Typography>
                <br />
                <Typography>We want you to feel confident using the Services. However, no system can be completely secure. Therefore, although we take steps to secure your information, we do not promise, and you should not expect, that your personally identifiable information, usage data or other communications will always remain secure. We will notify you by email if we have reason to believe that your personal information has been compromised due to a security breach or used in an unauthorized manner, but by using this Service, in accordance with the Terms of Use you agree to release us from any and all claims arising out of unauthorized use of your information.</Typography>
              </>
         )}
          />
        </ListItem>
        <ListItem alignItems="flex-start">
          <ListItemText
            primary={<Typography sx={{ textDecoration: 'underline', fontWeight: 'bold' }}>Your Choices Regarding the Information We Collect</Typography>}
            secondary={(
              <>
                <br />
                <Typography>
                  You may choose to:
                </Typography>
                <br />
                <Typography component="ul">
                  <li>
                    Update and correct your personal information in your Account
                  </li>
                </Typography>
                <br />
                <Typography component="ul">
                  <li>
                    Object to the processing of your personal information
                  </li>
                </Typography>
                <br />
                <Typography component="ul">
                  <li>
                    Request to have your personal information or usage data deleted or restricted from our Service
                  </li>
                </Typography>
                <br />
                <Typography component="ul">
                  <li>
                    Request for portability of your personal information
                  </li>
                </Typography>
                <br />
                <Typography component="ul">
                  <li>
                    Cancel your account
                  </li>
                </Typography>
                <br />
                <Typography>
                  To do any of these, simply notify us of this decision by one of these methods:
                </Typography>
                <br />
                <Typography component="ul">
                  <li>
                    Follow the unsubscribe link in any marketing email or follow the directions included in any other promotional material received from Pnyx
                  </li>
                </Typography>
                <br />
                <Typography component="ul">
                  <li>
                    Send an email to us at info@pnyx.io.
                  </li>
                </Typography>
                <br />
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Data Retention</Typography>
                <br />
                <Typography>We will retain your profile information and usage data for as long as your account is active or as needed to provide you services, comply with our legal obligations, resolve disputes, and enforce our agreements. In most cases, this period extends for as long as your Account is active. If you close your account, we may still retain certain information associated with your account for analytical purposes and recordkeeping integrity, as well as to prevent fraud, collect any fees owed, enforce our terms and conditions, take actions we deem necessary to protect the integrity of our web site or our users, or take other actions otherwise permitted by law. Deactivating your account does not automatically delete your account or usage data from our database, but regardless of any retention policy we will make reasonable efforts to enable you to delete your profile and personally identifiable information from our database upon request.</Typography>
                <br />
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Third Party Services and Links to Other Websites</Typography>
                <br />
                <Typography>Our website may contain links to other websites including those of our service partners and other service providers, many of which have their own privacy policies. Be sure to review the privacy policy on any site you are visiting, whether directly or through the Service.</Typography>
                <br />
                <Typography>Additionally, we may integrate third party services in to our Service in order to personalize your experience. This policy only covers the use of cookies by Pnyx. Cookies placed by third party services are governed by the third party terms and privacy policies applicable to those services (which we encourage you to read).</Typography>
                <br />
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Children&apos;s Privacy</Typography>
                <br />
                <Typography>Use of the Service is intended for individuals age 18 and above, and we do not knowingly seek or collect personal information from anyone under the age of 18.</Typography>
                <br />
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Using the Services from Outside the United States</Typography>
                <br />
                <Typography>This Privacy Policy is intended to cover collection of information from residents of the United States and is not intended for users located outside the United States. If you are accessing the Service from outside the United States, please be aware that your information may be transferred to, stored, and processed in the United States where our servers are located and our central database is operated. The data protection and other laws of the United States and other countries might not be as comprehensive as those in your country. By using the Service, you understand that your information may be transferred to our facilities and those third parties with whom we share it as described in this Privacy Policy. </Typography>
                <br />
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Changes to this Privacy Policy</Typography>
                <br />
                <Typography>Please note that this Privacy Policy may change from time to time. We will post any Privacy Policy changes on this page and, if the changes are significant or involve changes to the way we use personal information, we will notify you by delivering an announcement via our Service or your account email. If you opt out of communications from Pnyx, you may not receive these notifications, however they will still govern your use of the Service, and you are responsible for proactively checking for any changes. If you continue to use the Service after changes have been posted, you agree to abide by and be bound by the modified privacy policy. Each version of this Privacy Policy will be identified by its effective date found at the bottom of this page. </Typography>
                <br />
                <Typography>If you have any additional questions or concerns about this Privacy Policy, please feel free to contact us any time through this service or email us at info@pnyx.io.</Typography>
              </>
         )}
          />
        </ListItem>
      </List>
    </Paper>
  );
}

export default PrivacyPolicy;
