import React from 'react';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

import isXsScreen from '../../common/utils/isXsScreen';

function LoadingArticles() {
  const xsScreen = isXsScreen();

  return (
    <>
      <Grid
        item
        xs={12}
        sx={{
          mx: 'auto', display: 'flex', justifyContent: 'center',
        }}
      >
        <Stack spacing={1}>
          <Skeleton variant="rectangular" width={xsScreen ? 350 : 500} height={270} />
          <Skeleton variant="rectangular" width={xsScreen ? 350 : 500} height={20} />
          <Skeleton variant="rectangular" width={xsScreen ? 350 : 500} height={20} />
          <Skeleton variant="rectangular" width={xsScreen ? 350 : 500} height={20} />
        </Stack>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          mx: 'auto', display: 'flex', justifyContent: 'center',
        }}
      >
        <Stack spacing={1}>
          <Skeleton variant="rectangular" width={xsScreen ? 350 : 500} height={270} />
          <Skeleton variant="rectangular" width={xsScreen ? 350 : 500} height={20} />
          <Skeleton variant="rectangular" width={xsScreen ? 350 : 500} height={20} />
          <Skeleton variant="rectangular" width={xsScreen ? 350 : 500} height={20} />
        </Stack>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          mx: 'auto', display: 'flex', justifyContent: 'center',
        }}
      >
        <Stack spacing={1}>
          <Skeleton variant="rectangular" width={xsScreen ? 350 : 500} height={270} />
          <Skeleton variant="rectangular" width={xsScreen ? 350 : 500} height={20} />
          <Skeleton variant="rectangular" width={xsScreen ? 350 : 500} height={20} />
          <Skeleton variant="rectangular" width={xsScreen ? 350 : 500} height={20} />
        </Stack>
      </Grid>
    </>

  );
}

export default LoadingArticles;
