export const nonPreferredImageDomains = [
  'www.rnz.co.nz',
  'www.scmp.com',
  'www.bbc.co.uk',
  'hongkongfp.com',
  'abcnews.go.com',
  'theatlasnews.co',
  'www.hindustantimes.com',
  'tech.slashdot.org',
  'apple.slashdot.org',
  'slashdot.org',
  'news.slashdot.org',
  'yro.slashdot.org',
  'tass.com',
  'aviationweek.com',
];

export const placeholderThumbnail = 'https://feqphydusiltannqkkhk.supabase.co/storage/v1/object/public/stock-imagry/fade-placholder.png';
