import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

import isXsScreen from '../../../common/utils/isXsScreen';

function ArticleLoading() {
  const xsScreen = isXsScreen();

  return (
    <Stack spacing={1} sx={{ mt: 5 }}>
      <Skeleton variant="rectangular" width={xsScreen ? '100%' : 500} height={270} />
      <Skeleton variant="rectangular" width={xsScreen ? '100%' : 500} height={20} />
      <Skeleton variant="rectangular" width={xsScreen ? '100%' : 500} height={20} />
      <Skeleton variant="rectangular" width={xsScreen ? '100%' : 500} height={20} />
    </Stack>
  );
}

export default ArticleLoading;
