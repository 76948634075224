import React from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';

function NavigatorSectionCollapsed({
  route,
  navItems,
}) {
  return (
    <>
      {navItems.map((item) => (
        <Tooltip key={item.title} title={item.title} placement="right">
          <IconButton
            component={Link}
            to={item.link}
            sx={route === item.link ? {
              color: 'text.primary',
              bgcolor: 'primary.dark',
              mx: 'auto',
              my: 1,
            } : {
              color: 'text.primary',
              mx: 'auto',
              my: 1,
            }}
          >
            {route === item.link ? item.activeIcon : item.inactiveIcon}
          </IconButton>
        </Tooltip>
      ))}
      <Tooltip title="Join Beta!" placement="right">
        <IconButton
          component={Link}
          to="/join-beta"
          sx={{ color: 'secondary.main', mx: 'auto', my: 1 }}
        >
          <AddIcon />
        </IconButton>
      </Tooltip>
    </>
  );
}

NavigatorSectionCollapsed.propTypes = {
  route: PropTypes.string.isRequired,
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      activeIcon: PropTypes.element.isRequired,
      inactiveIcon: PropTypes.element.isRequired,
    }),
  ).isRequired,
};

export default NavigatorSectionCollapsed;
