import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CopyrightOutlinedIcon from '@mui/icons-material/CopyrightOutlined';

function CopyrightSection() {
  return (
    <Stack
      alignItems="center"
      direction="row"
      spacing={1}
      sx={{ color: 'text.hint' }}
    >
      <CopyrightOutlinedIcon color="inherit" fontSize="small" />
      <Typography color="inherit" variant="caption">Pnyx Inc 2024</Typography>
    </Stack>
  );
}

export default CopyrightSection;
