import React from 'react';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import isXsScreen from '../utils/isXsScreen';

/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */

function JoinBetaButton({ sx, ...restProps }) {
  const isXs = isXsScreen();

  return (
    <Button
      variant="contained"
      color="secondary"
      sx={{
        mt: 2,
        fontSize: isXs ? '1rem' : '1.25rem',
        fontWeight: 'bold',
        py: isXs ? 1 : 2,
        px: isXs ? 4 : 8,
        letterSpacing: 2,
        borderRadius: 4,
        ...sx,
      }}
      component={Link}
      to="/join-beta"
      {...restProps}
    >
      Join Beta
    </Button>
  );
}

export default JoinBetaButton;
