import React from 'react';
import Box from '@mui/material/Box';

function JoinBeta() {
  return (
    <Box sx={{
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    }}
    >
      <iframe
        title="Join Beta Form"
        src="https://docs.google.com/forms/d/e/1FAIpQLSeTiijKAzyNZUwDsC9iq_X1-QzkIOaegm-cuVBC2-PIOW1I-g/viewform?embedded=true"
        width="640"
        height="1050"
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
      >
        Loading…
      </iframe>
    </Box>
  );
}

export default JoinBeta;
