import React from 'react';
import ReactGA from 'react-ga4';
import { Route, Routes, useLocation } from 'react-router-dom';

// --> Layout components
import AppLayout from './common/components/AppLayout/AppLayout';
import BlogLayout from './common/components/BlogLayout/BlogLayout';

// --> App components
import BrowseArticles from './pages/BrowseArticles/BrowseArticles';
import ViewArticle from './pages/ViewArticles/ViewArticle';

// --> Blog Components components
import About from './pages/About/About';
import AcceptableUse from './pages/AcceptableUse/AcceptableUse';
import Contact from './pages/Contact/Contact';
import PrivacyPolicy from './pages/Privacy/PrivacyPolicy';
import TermsAndConditions from './pages/Terms/TermsAndConditions';

// --> Landing page
import Landing from './pages/Landing/Landing';

// --> Not found
import NotFound from './pages/NotFound/NotFound';

// Providers
import { ArticleProvider } from './common/apis/ArticleProvider';
import { FeedProvider } from './common/apis/FeedProvider';
import JoinBeta from './pages/JoinBeta/JoinBeta';

/* eslint-disable max-len */

function App() {
  const location = useLocation();
  const searchParams = new URLSearchParams(window.location.search);
  const utmSource = searchParams.get('utm_source');
  const utmMedium = searchParams.get('utm_medium');
  const utmCampaign = searchParams.get('utm_campaign');

  const GA4_MEASUREMENT_ID = process.env.REACT_APP_GA4_MEASUREMENT_ID;
  ReactGA.initialize(GA4_MEASUREMENT_ID, {
    gaOptions: {
      utm_source: utmSource,
      utm_medium: utmMedium,
      utm_campaign: utmCampaign,
    },
  });

  ReactGA.send({ hitType: 'pageview', page: location.pathname, title: location.pathname });

  return (
    <div>
      <FeedProvider>
        <ArticleProvider>
          <Routes>

            {/* App Routes */}
            <Route path="/article" element={(<AppLayout><ViewArticle /></AppLayout>)} />
            <Route path="/feed" element={(<AppLayout filterBar><BrowseArticles /></AppLayout>)} />

            {/* Blog & Defualt Routes */}
            <Route path="/" element={(<BlogLayout><Landing /></BlogLayout>)} />
            <Route path="/about" element={(<BlogLayout><About /></BlogLayout>)} />
            <Route path="/contact" element={(<BlogLayout><Contact /></BlogLayout>)} />
            <Route path="/join-beta" element={(<BlogLayout><JoinBeta /></BlogLayout>)} />
            <Route path="/privacy" element={(<BlogLayout><PrivacyPolicy /></BlogLayout>)} />
            <Route path="/terms" element={(<BlogLayout><TermsAndConditions /></BlogLayout>)} />
            <Route path="/use" element={(<BlogLayout><AcceptableUse /></BlogLayout>)} />

            {/* Not found */}
            <Route path="*" element={<NotFound />} />

          </Routes>
        </ArticleProvider>
      </FeedProvider>
    </div>
  );
}

export default App;
