// React
import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import XIcon from '@mui/icons-material/X';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { FaDiscord } from 'react-icons/fa';
import { Link as domLink } from 'react-router-dom';

// Components
import { ReactComponent as NameIconSvg } from '../../../assets/name-icon.svg';
import {
  discord, x, youtube,
} from '../../utils/SocialLinks';

function BlogFooter() {
  const isXsScreen = useMediaQuery('(max-width:611.95px)');

  const buttonStyles = {
    color: 'primary.light',
    '&:hover': { textDecoration: 'underline', backgroundColor: 'transparent' },
    cursor: 'pointer',
  };

  const linkData = [
    { name: 'About', route: '/about' },
    { name: 'Contact', route: '/contact' },
    { name: 'Privacy', route: '/privacy' },
    { name: 'Terms', route: '/terms' },
    { name: 'Use', route: '/use' },
  ];

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', pt: 5, pb: 2, px: isXsScreen ? 1 : 20, bgcolor: 'primary.dark',
    }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
        <Box component={domLink} to="/">
          <NameIconSvg style={{ cursor: 'pointer' }} />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Stack spacing={1} alignItems="flex-start">
            {linkData.map((link) => (
              <Typography
                key={link.name}
                component={domLink}
                to={link.route}
                sx={buttonStyles}
              >
                {link.name}
              </Typography>
            ))}
            <Stack direction="row" spacing={2}>
              <Link href={x} target="_blank" rel="noopener noreferrer" underline="none">
                <XIcon sx={{
                  height: '18px', width: '18px', cursor: 'pointer', color: 'primary.light',
                }}
                />
              </Link>
              <Link href={youtube} target="_blank" rel="noopener noreferrer" underline="none">
                <YouTubeIcon sx={{
                  height: '20px', width: '20px', cursor: 'pointer', color: 'primary.light',
                }}
                />
              </Link>
              <Link href={discord} target="_blank" rel="noopener noreferrer" underline="none">
                <FaDiscord style={{
                  height: '20px', width: '20px', cursor: 'pointer', color: 'rgb(103, 103, 103)',
                }}
                />
              </Link>
            </Stack>
          </Stack>
        </Box>
      </Box>
      <Box>
        <Typography variant="body2" color="primary.light" align="center">
          © PNYX INC 2024
        </Typography>
      </Box>
    </Box>
  );
}

export default BlogFooter;
