// ArticleProvider.jsx
import React, {
  createContext, useContext, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';

import { supabase } from '../utils/supabaseClient';

const ArticleContext = createContext();

export function ArticleProvider({ children }) {
  const [articleData, setArticleData] = useState([]);
  const [articleError, setArticleError] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchArticle = async (id) => {
    setLoading(true);
    try {
      const { data, error } = await supabase
        .from('articles')
        .select('id, created_at, pnyx_category, title, text, summary, sources:source_index(source:sources(id, published_at, link, title, image_url, org:organizations(org_id, name, image_url, link, paywall, annoying_ads)))')
        .order('created_at', { ascending: false, nullsFirst: false })
        .eq('id', id)
        .single();

      if (error) {
        setArticleError(error.message);
        throw new Error(error.message);
      } else {
        setArticleError(null);
        setArticleData(data);
      }
    } catch (error) {
      console.error('An error occurred while fetching articles:', error);
    }
    setLoading(false);
  };

  const value = useMemo(() => ({
    articleData,
    articleError,
    loading,
    fetchArticle,
  }), [articleData, articleError, loading, fetchArticle]);

  return (
    <ArticleContext.Provider value={value}>
      {children}
    </ArticleContext.Provider>
  );
}

ArticleProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useArticle = () => useContext(ArticleContext);
