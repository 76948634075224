const FormatTime = {

  formatSeconds(seconds) {
    if (Number.isNaN(seconds)) {
      return '00:00';
    }

    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = date.getUTCSeconds().toString().padStart(2, '0');
    if (hh) {
      return `${hh}:${mm.toString().padStart(2, '0')}:${ss}`;
    }

    return `${mm}:${ss}`;
  },

  formatDuration(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    let timeString = '';
    if (hours > 0) {
      timeString += `${hours}hr `;
    }
    if (minutes > 0) {
      timeString += `${minutes}min`;
    }
    return timeString.trim();
  },

  convertTimestampToSeconds(timestamp) {
    const [minutes, seconds] = timestamp.split(':');
    return (Number(minutes) * 60) + Number(seconds);
  },

  dateToElapsedTime(date) {
    const now = Date.now();
    const mediaDate = Date.parse(date);
    const timeDiff = now - mediaDate;
    let convertedDate = '';

    if (timeDiff >= 3.1556952E+12) {
      convertedDate = `${Math.round(timeDiff / 3.1556952E+12)} centuries ago`;
    } else if (timeDiff >= 3.1556952E+11) {
      convertedDate = `${Math.round(timeDiff / 3.1556952E+11)} decades ago`;
    } else if (timeDiff >= 3.1556952E+10) {
      convertedDate = `${Math.round(timeDiff / 3.1556952E+10)} years ago`;
    } else if (timeDiff >= 2.629746E+9) {
      convertedDate = `${Math.round(timeDiff / 2.629746E+9)} months ago`;
    } else if (timeDiff >= 604800000) {
      convertedDate = `${Math.round(timeDiff / 604800000)} weeks ago`;
    } else if (timeDiff >= 86400000) {
      convertedDate = `${Math.round(timeDiff / 86400000)} days ago`;
    } else if (timeDiff >= 3600000) {
      convertedDate = `${Math.round(timeDiff / 3600000)} hours ago`;
    } else if (timeDiff >= 60000) {
      convertedDate = `${Math.round(timeDiff / 60000)} mins ago`;
    } else {
      convertedDate = `${Math.round(timeDiff / 1000)} secs ago`;
    }
    return convertedDate;
  },

  dateToFuture(date) {
    const now = Date.now();
    const mediaDate = Date.parse(date);
    const timeDiff = mediaDate - now; // Calculate the time difference in milliseconds
    let convertedDate = '';

    if (timeDiff >= 3.1556952E+12) {
      convertedDate = Math.round(timeDiff / 3.1556952E+12) ? `${Math.round(timeDiff / 3.1556952E+12)} centurie` : `${Math.round(timeDiff / 3.1556952E+12)} centuries`;
    } else if (timeDiff >= 3.1556952E+11) {
      convertedDate = Math.round(timeDiff / 3.1556952E+11) ? `${Math.round(timeDiff / 3.1556952E+11)} decade` : `${Math.round(timeDiff / 3.1556952E+11)} decades`;
    } else if (timeDiff >= 3.1556952E+10) {
      convertedDate = Math.round(timeDiff / 3.1556952E+10) ? `${Math.round(timeDiff / 3.1556952E+10)} year` : `${Math.round(timeDiff / 3.1556952E+10)} years`;
    } else if (timeDiff >= 2.629746E+9) {
      convertedDate = Math.round(timeDiff / 2.629746E+9) ? `${Math.round(timeDiff / 2.629746E+9)} month` : `${Math.round(timeDiff / 2.629746E+9)} months`;
    } else if (timeDiff >= 604800000) {
      convertedDate = Math.round(timeDiff / 604800000) ? `${Math.round(timeDiff / 604800000)} week` : `${Math.round(timeDiff / 604800000)} weeks`;
    } else if (timeDiff >= 86400000) {
      convertedDate = Math.round(timeDiff / 86400000) === 1 ? `${Math.round(timeDiff / 86400000)} day` : `${Math.round(timeDiff / 86400000)} days`;
    } else if (timeDiff >= 3600000) {
      convertedDate = Math.round(timeDiff / 3600000) ? `${Math.round(timeDiff / 3600000)} hour` : `${Math.round(timeDiff / 3600000)} hours`;
    } else if (timeDiff >= 60000) {
      convertedDate = Math.round(timeDiff / 60000) ? `${Math.round(timeDiff / 60000)} minute` : `${Math.round(timeDiff / 60000)} minutes`;
    } else {
      convertedDate = Math.round(timeDiff / 1000) ? `${Math.round(timeDiff / 1000)} second` : `${Math.round(timeDiff / 1000)} seconds`;
    }

    if (timeDiff > 0) {
      return `${convertedDate}`;
    } if (timeDiff < 0) {
      return `${convertedDate}`;
    }
    return 'just now';
  },

};

export default FormatTime;
