import React from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import NewReleasesOutlinedIcon from '@mui/icons-material/NewReleasesOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import FormatTime from '../../../common/utils/FormatTime';

/* eslint-disable react/prop-types */

function ArticleSource({ index, source }) {
  const defaultImage = 'https://static.vecteezy.com/system/resources/previews/016/916/479/original/placeholder-icon-design-free-vector.jpg';

  return (
    <Box sx={{ p: 1 }}>
      <Stack direction="row" spacing={1}>
        <Typography>
          {index}
          .
        </Typography>
        <Stack>
          <Typography
            component={Link}
            to={source.source.link}
            target="_blank"
            sx={{
              textDecoration: 'none',
              color: 'inherit',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            {source.source.title}
          </Typography>
          <Stack direction="row" spacing={1} alignItems="center" sx={{ mt: 1 }}>
            <Avatar src={source.source.org.image_url ? source.source.org.image_url : defaultImage} sx={{ height: '20px', width: '20px' }} />
            <Typography variant="body2" color="text.hint">{source.source.org.name}</Typography>
            <Typography variant="body2" color="text.hint">{source.source.published_at ? FormatTime.dateToElapsedTime(source.source.published_at) : null}</Typography>
            {source.source.org.paywall && (
              <Tooltip title="May have paywall">
                <PaidOutlinedIcon sx={{ height: '20px', width: '20px', color: 'warning.main' }} />
              </Tooltip>
            )}
            {source.source.org.annoying_ads && (
              <Tooltip title="Filled with ads">
                <NewReleasesOutlinedIcon sx={{ height: '20px', width: '20px', color: 'info.main' }} />
              </Tooltip>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}

ArticleSource.propTypes = {
  index: PropTypes.number.isRequired,
  source: PropTypes.shape({
    title: PropTypes.string,
    link: PropTypes.string,
    image_url: PropTypes.string.isRequired,
    published_at: PropTypes.string,
    org: PropTypes.shape({
      paywall: PropTypes.oneOfType(
        [PropTypes.bool, PropTypes.string],
      ).isRequired,
      annoyingAds: PropTypes.oneOfType(
        [PropTypes.bool, PropTypes.string],
      ).isRequired,
      name: PropTypes.string.isRequired,
      image_url: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default ArticleSource;
