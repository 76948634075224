import React from 'react';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { ReactComponent as IconSvg } from '../../../assets/icon.svg';

function LogoSectionMobile() {
  return (
    <Box
      component={Link}
      to="/"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '70px',
        marginTop: 1,
        marginBottom: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          my: 'auto',
        }}
      >
        <IconSvg style={{ cursor: 'pointer' }} />
      </Box>
    </Box>
  );
}

export default LogoSectionMobile;
