import React, {
  useCallback, useEffect, useRef,
} from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LoadingArticles from './LoadingArticles.js';
import ArticleCard from '../../common/components/ArticleCard';

import isXsScreen from '../../common/utils/isXsScreen.js';
import { useFeed } from '../../common/apis/FeedProvider.js';

function BrowseArticleContainer() {
  const isXs = isXsScreen();
  const {
    articles, loading, fetchError, setPageNumber, setScrollPosition, scrollPosition,
  } = useFeed();

  const observer = useRef();
  const lastArticleRef = useCallback((node) => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setPageNumber((prevPageNumber) => prevPageNumber + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [loading]);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    window.scrollTo({ top: scrollPosition });
  }, []);

  return (
    <>
      <Grid container spacing={isXs ? 1 : 2} sx={{ mt: isXs ? 1 : 3.5 }}>
        {fetchError && <Typography align="center" sx={{ mt: '200px', mx: 'auto' }}>Error loading articles. Check internet or try again later.</Typography>}
        {articles && articles.length > 1 && (
          <>
            {articles.map((article, index) => {
              if (articles.length === index + 1) {
                return (
                  <Grid
                    item
                    xs={12}
                    key={article.id}
                    ref={lastArticleRef}
                    sx={{
                      mx: 'auto', display: 'flex', justifyContent: 'center',
                    }}
                  >
                    <ArticleCard article={article} />
                  </Grid>
                );
              }
              return (
                <Grid
                  item
                  xs={12}
                  key={article.id}
                  sx={{
                    mx: 'auto', display: 'flex', justifyContent: 'center',
                  }}
                >
                  <ArticleCard article={article} />
                </Grid>
              );
            })}
          </>
        )}
      </Grid>
      {loading && articles.length === 0 && <LoadingArticles />}
      {loading && articles.length > 0 && <CircularProgress sx={{ mt: 2, mb: isXs ? '70px' : 2 }} />}
    </>
  );
}

export default BrowseArticleContainer;
