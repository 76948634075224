import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import AppsIcon from '@mui/icons-material/Apps';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import WorkIcon from '@mui/icons-material/Work';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import PaidIcon from '@mui/icons-material/Paid';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import PublicIcon from '@mui/icons-material/Public';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import FlagCircleOutlinedIcon from '@mui/icons-material/FlagCircleOutlined';
import ScienceIcon from '@mui/icons-material/Science';
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';
import { Link, useLocation } from 'react-router-dom';

import isXsScreen from '../../utils/isXsScreen';
import { ReactComponent as NameIconSvg } from '../../../assets/name-icon.svg';
import { useFeed } from '../../apis/FeedProvider';

function FilterBarHeaderXsScreen() {
  const location = useLocation();
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
      mx: 1,
      pb: 1,
    }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '70px',
          ml: '-7px',
          my: '-20px',
        }}
      >
        <NameIconSvg component={Link} to="/" style={{ cursor: 'pointer', marginLeft: '8px' }} />
      </Box>
      {location.pathname === '/feed'
            && (
            <Box sx={{ alignSelf: 'flex-end', marginBottom: '-5px' }}>
              <Button
                variant="contained"
                color="secondary"
                sx={{ textTransform: 'none', height: '35px' }}
                component={Link}
                to="/join-beta"
              >
                <Typography variant="subtitle1" fontWeight="bold">Join Beta!</Typography>
              </Button>
            </Box>
            )}
    </Box>

  );
}

function FilterBarHeader() {
  return (
    <Stack spacing={1} direction="row" alignItems="center" justifyContent="center" sx={{ mb: 1 }}>
      <ArticleOutlinedIcon sx={{ fontSize: '50px', mx: 'auto' }} />
      <Typography variant="h4" align="center" gutterBottom>News Feed</Typography>
    </Stack>
  );
}

function FilterBar() {
  const { filter, filterResults } = useFeed();
  const xsScreen = isXsScreen();
  const location = useLocation();

  const categories = [
    {
      category: 'all', label: 'All', icon: <AppsIcon />, iconSelected: <AppsIcon />,
    },
    {
      category: 'business', label: 'Business', icon: <WorkOutlineIcon />, iconSelected: <WorkIcon />,
    },
    {
      category: 'economics', label: 'Economics', icon: <PaidOutlinedIcon />, iconSelected: <PaidIcon />,
    },
    {
      category: 'geopolitics', label: 'Geopolitics', icon: <PublicIcon />, iconSelected: <PublicIcon />,
    },
    {
      category: 'politics', label: 'Politics', icon: <FlagCircleOutlinedIcon />, iconSelected: <FlagCircleIcon />,
    },
    {
      category: 'tech_science', label: 'Science & Tech', icon: <ScienceOutlinedIcon />, iconSelected: <ScienceIcon />,
    },
  ];

  const containerStyle = xsScreen ? {
    pt: 2,
  } : {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    pt: 2,
  };

  const handleFilterResults = (category) => {
    filterResults(category);
    window.scrollTo(0, 0);
  };

  return (
    <Box sx={containerStyle}>
      {xsScreen && <FilterBarHeaderXsScreen />}
      {!xsScreen && location.pathname === '/feed' && <FilterBarHeader />}
      {location.pathname === '/feed' && (
      <Box sx={{
        overflowX: 'auto', my: 1, mx: xsScreen ? 1 : '50px', ml: xsScreen ? 1 : 0,
      }}
      >
        <Stack direction="row" spacing={0.75}>
          {categories.map(({
            label,
            icon,
            iconSelected,
            category,
          }) => (
            <Button
              key={label}
              startIcon={category === filter ? iconSelected : icon}
              size="small"
              variant="contained"
              onClick={() => handleFilterResults(category)}
              sx={{
                borderRadius: 2,
                minWidth: 'auto',
                whiteSpace: 'nowrap',
                ...(category === filter
                  ? { bgcolor: 'primary.dark', boxShadow: 5 }
                  : { bgcolor: 'primary' }),
              }}
            >
              {label}
            </Button>
          ))}
        </Stack>
      </Box>
      )}
    </Box>
  );
}

export default FilterBar;
