import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import SourceOutlinedIcon from '@mui/icons-material/SourceOutlined';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Stack } from '@mui/material';
import ArticleMedia from './ArticleMedia';
import ArticleDetails from './ArticleDetails';
import ArticleBody from './ArticleBody';
import ArticleSource from './ArticleSource';
import isXsScreen from '../../../common/utils/isXsScreen';

function ArticleContainer({ article }) {
  const isXsSceen = isXsScreen();

  return (
    <Box sx={{ maxWidth: isXsSceen ? '100%' : '640px' }}>
      <Button
        component={Link}
        to="/feed"
        variant="text"
        size="small"
        startIcon={<ArrowBackIcon />}
        sx={{
          color: 'text.primary',
          borderColor: 'text.primary',
          mb: 1,
        }}
      >
        Back
      </Button>
      <ArticleMedia sources={article.sources} />
      <ArticleDetails title={article.title} pubDate={article.created_at} />
      <ArticleBody content={article.text} createdAt={article.created_at} />
      <Paper sx={{
        borderRadius: '15px', p: 2, pb: 6, mb: isXsScreen ? 5 : 4,
      }}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <SourceOutlinedIcon />
          <Typography>Sources</Typography>
        </Stack>
        {article.sources.map((source, index) => (
          <ArticleSource
            key={source.source.id}
            source={source}
            index={index + 1}
          />
        ))}
      </Paper>
    </Box>
  );
}

ArticleContainer.propTypes = {
  article: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    created_at: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    sources: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      link: PropTypes.string,
      image_url: PropTypes.string,
      pubDate: PropTypes.string,
      org: PropTypes.shape({
        org_id: PropTypes.string.isRequired,
        paywall: PropTypes.bool.isRequired,
        annoyingAds: PropTypes.bool.isRequired,
        name: PropTypes.string.isRequired,
        image_url: PropTypes.string.isRequired,
      }),
    })).isRequired,
  }).isRequired,
};

export default ArticleContainer;
