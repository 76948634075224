import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

import BlogHeader from './BlogHeader';
import BlogFooter from './BlogFooter';
import isXsScreen from '../../utils/isXsScreen';

function BlogLayout({ children }) {
  const isXs = isXsScreen();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <BlogHeader />
      <Box sx={{ py: isXs ? 1 : 5 }}>
        {children}
      </Box>
      <BlogFooter />
    </Box>

  );
}

BlogLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BlogLayout;
