// React
import React from 'react';

// External Components
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

/* eslint-disable max-len */

function TermsAndConditions() {
  return (
    <Paper sx={{ m: 4 }}>
      <List sx={{ width: '100%' }}>
        <ListItem>
          <Typography variant="h4">Pnyx Terms And Conditions</Typography>
        </ListItem>
        <ListItem>
          <Typography variant="h6">Last Modified: April 7th, 2023</Typography>
        </ListItem>
        <ListItem alignItems="flex-start">
          <ListItemText
            primary={<Typography sx={{ textDecoration: 'underline', fontWeight: 'bold' }}>Introduction</Typography>}
            secondary={(
              <>
                <br />
                <Typography>Welcome to the Pnyx Inc. (“Pnyx”) website, application and proprietary software platform that connects individuals and provides a centralized platform for open debate to anyone who creates an account with Pnyx. The Service enables registered users (“Users,” “you” or “your”) to submit, view, and rate recordings of live one-on-one debates on different topics for purposes of facilitating open debate.</Typography>
                <br />
                <Typography>Every time you visit or use features of the Service, you agree to be bound by these Terms of Use (“Terms”). These Terms outline your rights, obligations and restrictions regarding your use of the Service, so please read them carefully. If you do not agree to be bound by the Terms and all applicable laws, you should discontinue use of the Service immediately. In addition to these Terms, we have adopted the Pnyx Privacy Notice (“Privacy Notice”). Please read our Privacy Notice carefully for information relating to our collection, use, and disclosure of your personal information.</Typography>
                <br />
                <Typography>Pnyx may modify the Terms from time to time and each modification will be effective when it is posted on the Service. We will notify you of substantive modifications to these Terms the first time you access the Service following any such modification, and you agree to be bound to any changes to the Terms through your continued use of the Service.</Typography>
                <br />
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>User Eligibility</Typography>
                <br />
                <Typography>Each User hereby warrants that the User is either an individual either more than 18 years of age, or an emancipated minor, or possess legal parental or guardian consent, and is fully able and competent to enter into the Terms, conditions, obligations, affirmations, representations, and warranties set forth in these Terms, and to abide by and comply with these Terms. In any case, the User affirms to be over the age of 13, as the Services are not intended for children under 13.</Typography>
                <br />
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Creating an Account</Typography>
                <br />
                <Typography>You agree to follow the prompts on our platform to create your account (your “Account”). You must provide certain personal information such as your name, address, email address, and other information we may request to identify you and establish your Account, as described in our Privacy Notice.</Typography>
                <br />
                <Typography>Please refer to our Privacy Notice for information about how we protect your personal information. You are responsible for ensuring that any personal information you provide is accurate and up to date. Pnyx reserves the right to verify the accuracy of the information you provide at any time. You are responsible for creating a username and a secure password and protecting your Account from unauthorized access. You agree to notify Pnyx immediately if you believe your user identification, password or other identifying information has been lost, stolen or otherwise compromised. You will be held responsible for any activity that occurs under your Account.</Typography>
                <br />
                <Typography>Once you have an Account, you may adjust your profile settings and start interacting on the Service.</Typography>
              </>
         )}
          />
        </ListItem>
        <ListItem alignItems="flex-start">
          <ListItemText
            primary={<Typography sx={{ textDecoration: 'underline', fontWeight: 'bold' }}>Guidelines, Rights and Remedies</Typography>}
            secondary={(
              <>
                <br />
                <Typography variant="subtitle1">Content Ownership and License to Use</Typography>
                <br />
                <Typography>The User acknowledges and agrees that:</Typography>
                <br />
                <Typography>(a) all video, images, information, data, text, software, music, sound, photographs, messages or other materials the User uploads, collects, communicates or transmits using the Services (“Content”) are the sole responsibility of the User;</Typography>
                <br />
                <Typography>(b) the User owns or has the necessary licenses, rights, consents, and permissions to use and authorizes Pnyx to use all copyrights, trademarks, trade secrets, patents and other intellectual property or proprietary rights in and to any and all Content User creates or uploads in accordance with these Terms; and</Typography>
                <br />
                <Typography>(c) the User has the necessary consent, release and /or permission of each identifiable individual person in the Content to use the presence and likeness of each such individual in the Content in the manner contemplated by these Terms.</Typography>
                <br />
                <Typography>You acknowledge that any Content you upload to Pnyx may be visible to other registered Users of Pnyx as authorized by the User or as necessary for us to provide the Services. Pnyx reserves the right to purge Content from its databases at any time and from time to time without notice in accordance with its document retention policies. In addition, Pnyx may disable User’s account and access to use the Services and Pnyx may recover from the User any losses, damages, costs or expenses incurred by Pnyx resulting from or arising out of User’s non-compliance with any provision of this Agreement or the Acceptable Use Policy.</Typography>
                <br />
                <Typography>By submitting ideas, suggestions, documents, and/or proposals (&quot;Contribution&quot;) to Pnyx through its suggestion or feedback webpages, you acknowledge and agree that: (a) your Contributions do not contain confidential or proprietary information; (b) Pnyx is not under any obligation of confidentiality, express or implied, with respect to the Contributions; (c) Pnyx shall be entitled to use or disclose (or choose not to use or disclose) such Contributions for any purpose, in any way, in any media worldwide; (d) Pnyx may have something similar to the Contributions already under consideration or in development; (e) your Contributions automatically become the property of Pnyx without any obligation of Pnyx to you; and (f) you are not entitled to any compensation or reimbursement of any kind from Pnyx under any circumstances.</Typography>
              </>
        )}
          />
        </ListItem>
        <ListItem alignItems="flex-start">
          <ListItemText
            primary={<Typography sx={{ textDecoration: 'underline', fontWeight: 'bold' }}>Permitted Use of the Service</Typography>}
            secondary={(
              <>
                <br />
                <Typography>You will be held solely responsible for your conduct on and use of the Service. You agree that you will not use or attempt to use this Service for any purpose other than to post, view or manage your Content or provide ratings to Content from other users.</Typography>
                <br />
                <Typography>In addition to the foregoing, Pnyx has implemented an Acceptable Use Policy for moderating Content on the Service. The Acceptable Use Policy is incorporated herein by reference, please review it carefully. Pnyx reserves the right at all times and for any reason or for no reason at all, in its sole discretion and without notice to you, to deny your access to and use of this Service.</Typography>
              </>
         )}
          />
        </ListItem>
        <ListItem alignItems="flex-start">
          <ListItemText
            primary={<Typography sx={{ textDecoration: 'underline', fontWeight: 'bold' }}>Intellectual Property Rights</Typography>}
            secondary={(
              <>
                <br />
                <Typography>Pnyx does not own any data or information that you submit in the course of creating your Account or any Content that is provided by you or collected from your use of the Service.</Typography>
                <br />
                <Typography>We or our licensors own and retain all proprietary rights in the Service. Neither the Service nor any portion of the Service may be reproduced, duplicated, copied, sold, resold, visited, or otherwise exploited for any commercial purpose without express written consent of Pnyx, unless it is in the public domain. You may not (directly or indirectly) decipher, decompile, disassemble, reverse engineer or otherwise attempt to derive source code or underlying ideas or algorithms of any part of the Service, or modify, translate or otherwise create derivative works of any part of the Service. Any modification of content, or any portion thereof, or use of the content for any other purpose constitutes an infringement of trademark or other proprietary rights of Pnyx or our third party service providers, and any unauthorized use terminates the permission to use the Service granted by Pnyx.</Typography>
                <br />
                <Typography>Except for the Content and any branding provided by our service partners, all content made available by Pnyx to you on the Service, such as report formats, text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software, is the property of Pnyx or its content/software suppliers and protected by United States and international copyright laws. All software used on our website and platform is the property of Pnyx or its software suppliers and protected by United States and international copyright laws.</Typography>
              </>
         )}
          />
        </ListItem>
        <ListItem alignItems="flex-start">
          <ListItemText
            primary={<Typography sx={{ textDecoration: 'underline', fontWeight: 'bold' }}>Notice and Take Down Procedures</Typography>}
            secondary={(
              <>
                <br />
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>1. Notice of Claims</Typography>
                <br />
                <Typography>If you believe any post or information on the Service infringes your copyright or trademark rights, you may request such content be removed by following the notice and take down procedures of the Digital Millennium Copyright Act. To follow those procedures, contact Pnyx’s copyright agent (identified below) and provide the following information:</Typography>
                <br />
                <br />
                <Typography component="ul">
                  <li>A clear statement identifying the works, or other materials believed to be infringed.</li>
                </Typography>
                <br />
                <br />
                <Typography component="ul">
                  <li>A statement from the copyright holder or authorized representative that the content is believed to be infringing.</li>
                </Typography>
                <br />
                <br />
                <Typography component="ul">
                  <li>Sufficient information about the location of the allegedly infringing content so that Pnyx can find and verify its existence.</li>
                </Typography>
                <br />
                <br />
                <Typography component="ul">
                  <li>Your name, telephone number, and e-mail address.</li>
                </Typography>
                <br />
                <br />
                <Typography component="ul">
                  <li>A statement from you under penalty of perjury that the information supplied is accurate, and that you are authorized to act on the copyright owner&apos;s behalf.</li>
                </Typography>
                <br />
                <br />
                <Typography component="ul">
                  <li>A signature or the electronic equivalent from the copyright holder or authorized representative.</li>
                </Typography>
                <br />
                <Typography>You acknowledge that if you fail to comply with all of the requirements of this section your DMCA notice may be invalid.</Typography>
                <br />
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>2. Counter-Notice</Typography>
                <br />
                <Typography>If you believe that your removed content (or content to which access was disabled) is not infringing, or that you have the authorization from the copyright owner, the copyright owner&apos;s agent, or pursuant to the law, to post and use the material in your content, you may send a counter-notice containing the following information to the Copyright Agent:</Typography>
                <br />
                <Typography component="ul">
                  <li>Your physical or electronic signature;</li>
                </Typography>
                <br />
                <Typography component="ul">
                  <li>Identification of the content that has been removed or to which access has been disabled and the location at which the content appeared before it was removed or disabled;</li>
                </Typography>
                <br />
                <Typography component="ul">
                  <li>A statement that you have a good faith belief that the content was removed or disabled as a result of mistake or a misidentification of the content; and</li>
                </Typography>
                <br />
                <Typography component="ul">
                  <li>Your name, address, telephone number, and e-mail address, a statement that you consent to the jurisdiction of the state or federal courts of the Commonwealth of Massachusetts, and a statement that you will accept service of process from the person who provided notification of the alleged infringement.</li>
                </Typography>
                <br />
                <Typography>
                  If a counter-notice is received by the Copyright Agent, we may send a copy of the counter-notice to the original complaining party informing that person that we may replace the removed content or cease disabling it in 10 business days. Unless the copyright owner files an action seeking a court order against the content provider, member, or user, the removed content may be replaced, or access to it restored in 10 business days or more after receipt of the counter-notice, at our sole discretion.
                </Typography>
                <br />
                <Typography>Pnyx&apos;s agent fornotice of copyright issues on the Service can be reached as follows:</Typography>
                <br />
                <Typography>Pnyx, Inc.</Typography>
                <br />
                <Typography>PO Box 828</Typography>
                <Typography>400 NW Gilman Blvd, Issaquah, WA 98027</Typography>
                <br />
                <Typography>Attn: Copyright Agent</Typography>
                <br />
                <Typography>If you are not sure whether material available online infringed your copyright, we suggest that you first contact an attorney.</Typography>
              </>
         )}
          />
        </ListItem>
        <ListItem alignItems="flex-start">
          <ListItemText
            primary={<Typography sx={{ textDecoration: 'underline', fontWeight: 'bold' }}>General Information</Typography>}
            secondary={(
              <>
                <br />
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Linking to Third Party Websites</Typography>
                <br />
                <Typography>For your convenience and in addition to the debating platform we facilitate, the Service may provide links to products or services, including payment processing services offered on other websites or applications. Unless expressly stated otherwise, Pnyx does not endorse, approve, sponsor, or control, and we are not in any way responsible for, any of the content, services, calculations, information, products, or materials available at or through any websites to which this Service may provide a link. By using the Service, you acknowledge and agree that Pnyx will not be responsible or liable to you or any other person for any damages or claims that might result from your use of such content, services, calculation, information, products, or materials. You should carefully review each website&apos;s privacy statements and conditions of use to understand your rights and responsibilities.</Typography>
                <br />
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Indemnification</Typography>
                <br />
                <Typography>You agree to indemnify and hold Pnyx, its parent, subsidiaries, affiliates, directors, officers, agents, and other partners and employees, harmless from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, including but not limited to reasonable attorneys&apos; fees, made by any third party due to or arising out of your account, use of the Service, or violation of the Terms. This defense and indemnification obligation will survive these Terms and your use of the Service.</Typography>
                <br />
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Disclaimer & Limitation of Liability</Typography>
                <br />
                <Typography>EXCEPT AS EXPRESSLY SET FORTH IN THESE TERMS, PNYX MAKES NO FURTHER REPRESENTATIONS OR WARRANTIES OF ANY KIND WHATSOEVER, EXPRESS OR IMPLIED. PNYX EXPRESSLY DISCLAIMS ALL IMPLIED WARRANTIES, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES AS TO THE CONDITION, MERCHANTABILITY, NON-INFRINGEMENT, DESIGN, OPERATION OR FITNESS FOR A PARTICULAR PURPOSE OF THE SERVICE. FOR AVOIDANCE OF DOUBT, THE DISCLAIMERS SET FORTH HEREIN DO NOT LIMIT ANY COVENANT, REPRESENTATION OR WARRANTY MADE BY PNYX IN THIS AGREEMENT.</Typography>
                <br />
                <Typography>YOU AGREE THAT UNDER NO CIRCUMSTANCES WILL PNYX BE LIABLE TO YOU OR ANY OTHER PERSON OR ENTITY FOR ANY SPECIAL, INCIDENTAL, CONSEQUENTIAL, PUNITIVE OR OTHER INDIRECT DAMAGES THAT RESULT FROM THE USE OF, OR THE INABILITY TO USE, THE SERVICE, EVEN IF PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT SHALL THE TOTAL LIABILITY OF PNYX TO YOU FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION RESULTING FROM YOUR USE OF THE SERVICE, WHETHER IN CONTRACT, TORT (INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE) OR OTHERWISE, EXCEED THE AMOUNTS PAID BY YOU TO PNYX IN THE IMMEDIATELY PRECEDING TWELVE-MONTH PERIOD.</Typography>
                <br />
                <Typography>
                  CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY, AND YOU MIGHT HAVE ADDITIONAL RIGHTS.
                </Typography>
                <br />
                <Typography>
                  While we strive to protect your information in accordance withour Privacy Notice, Pnyx cannot be liable for the privacy of personal information collected or stored on the Service, or otherwise connected with your use of the Service.
                </Typography>
                <br />
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Independent Contractor</Typography>
                <br />
                <Typography>
                  Pnyx&apos;s relationship with its Users shall be that of an independent contractor and nothing herein shall be construed to create a partnership, joint venture, or employer-employee relationship.
                </Typography>
                <br />
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Termination</Typography>
                <br />
                <Typography>
                  Pnyx may terminate or suspend your access to all or part of the Service, for any reason, including without limitation your breach of these Terms. In the event these Terms are terminated, the representations and warranties, indemnities, and limitations of liabilities set forth in these Terms will survive termination.
                </Typography>
                <br />
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Jurisdiction & Severability</Typography>
                <br />
                <Typography>
                  Pnyx operates the Service from its offices within the United States. The Services are designed for Users within the United States, and Pnyx makes no representations that content and materials on the Service are legal or appropriate for use from outside the United States. If you choose to access the Service from other locations, you do so at your own risk and are responsible for compliance with any and all local laws. You may not use the Service in violation of U.S. export laws and regulations.
                </Typography>
                <br />
                <Typography>
                  These Terms are not assignable, transferable, or sublicensable by you except with Pnyx&apos;s prior written consent. Pnyx may assign, transfer, or delegate any of its rights and obligations hereunder without consent.
                </Typography>
                <br />
                <Typography>
                  These Terms will be governed by and construed in accordance with the laws of the State of Washington without regard to its conflict of laws provisions. Any action brought against Pnyx to enforce these Terms or matters related to the Service will be brought in either the state courts or, if there is exclusive federal jurisdiction, the federal courts located in King County in the State of Washington. Any claim or cause of action you have with respect to use of the Service must be commenced within one (1) year after the claim arises. In any action or proceeding to enforce rights under the Terms, the prevailing party will be entitled to recover costs and attorneys&apos; fees.
                </Typography>
                <br />
                <Typography>
                  If any provision of these Terms is deemed void, unlawful, or otherwise unenforceable for any reason, that provision will be severed from these Terms and the remaining provisions of these Terms will remain in force. These Terms constitute the entire agreement between you and Pnyx concerning your use of the Service.
                </Typography>
                <br />
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>How To Contact Us</Typography>
                <br />
                <Typography>Should you have any questions or complaints regarding violations of these Terms, please contact us at info@pnyx.io.</Typography>
              </>
         )}
          />
        </ListItem>
      </List>
    </Paper>
  );
}

export default TermsAndConditions;
