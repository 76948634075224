import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
// import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function NavigatorSection({
  route,
  navItems,
}) {
  return (
    <List>
      {navItems.map((item) => (
        <ListItem key={item.title} component={Link} to={item.link} dense disableGutters>
          <Button
            variant="text"
            fullWidth
            startIcon={route === item.link ? item.activeIcon : item.inactiveIcon}
            sx={route === item.link
              ? {
                color: 'text.primary',
                fontWeight: 'bold',
                justifyContent: 'flex-start',
                alignItems: 'center',
                paddingLeft: '25px',
                textTransform: 'none',
              } : {
                color: 'text.primary',
                justifyContent: 'flex-start',
                alignItems: 'center',
                paddingLeft: '25px',
                textTransform: 'none',
              }}
          >
            <Typography variant="subtitle1" fontWeight={route === item.link && 'bold'} sx={{ px: '15px' }}>{item.title}</Typography>
          </Button>
        </ListItem>
      ))}
      {/* <ListItem dense disableGutters>
        <Button
          variant="text"
          fullWidth
          startIcon={<AccountBoxOutlinedIcon />}
          sx={{
            color: 'text.primary',
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingLeft: '25px',
            textTransform: 'none',
          }}
        >
          <Typography variant="subtitle1" sx={{ px: '15px' }}>Sing In</Typography>
        </Button>
      </ListItem> */}
      <Box sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
      >
        <Button
          variant="contained"
          color="secondary"
          sx={{
            textTransform: 'none', width: '80%', borderRadius: 2, my: 1, letterSpacing: 2,
          }}
          component={Link}
          to="/join-beta"
        >
          <Typography variant="subtitle1" fontWeight="bold">Join Beta!</Typography>
        </Button>
      </Box>
    </List>
  );
}

NavigatorSection.propTypes = {
  route: PropTypes.string.isRequired,
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      activeIcon: PropTypes.element.isRequired,
      inactiveIcon: PropTypes.element.isRequired,
    }),
  ).isRequired,
};

export default NavigatorSection;
