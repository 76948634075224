import React from 'react';
import Box from '@mui/material/Box';

function Contact() {
  return (
    <Box sx={{
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    }}
    >
      <iframe
        title="Contact Form"
        src="https://docs.google.com/forms/d/e/1FAIpQLSeWE5il8ZOVY5Gd_H1bR_pkppc6M_LYqDeJoEABdMyX0-G8VQ/viewform?embedded=true"
        width="640"
        height="1044"
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
      >
        Loading…
      </iframe>
    </Box>
  );
}

export default Contact;
