import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router-dom';

import ArticleContainer from './components/ArticleContainer';
import ArticleLoading from './components/ArticleLoading';
import { useArticle } from '../../common/apis/ArticleProvider';

function ViewArticle() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('a');
  const {
    articleData, articleError, loading, fetchArticle,
  } = useArticle();

  useEffect(() => {
    fetchArticle(id);
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box>
      {articleError
        && <Typography align="center" sx={{ mt: '200px', mx: 'auto' }}>Error loading article.  Check internet or try again later.</Typography>}
      {loading ? (
        <ArticleLoading />
      ) : (
        <ArticleContainer article={articleData} />
      )}
    </Box>
  );
}

export default ViewArticle;
