import React from 'react';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import PublicIcon from '@mui/icons-material/Public';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import FlagCircleOutlinedIcon from '@mui/icons-material/FlagCircleOutlined';
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import isXsScreen from '../utils/isXsScreen';
import isMScreen from '../utils/isMScreen';
import isLScreen from '../utils/isLScreen';
import FormatTime from '../utils/FormatTime';
import { nonPreferredImageDomains, placeholderThumbnail } from '../utils/ImageThumbnailLists';

function ArticleCard({ article }) {
  const xsScreen = isXsScreen();
  const isM = isMScreen();
  const isL = isLScreen();
  const summary = JSON.parse(article.summary);

  // Function to check if the URL is blacklisted
  const isWatermarked = (url) => {
    const domain = new URL(url).hostname;
    return nonPreferredImageDomains.includes(domain);
  };

  // Selecting the featured image, de-prioritizing blacklisted URLs
  const featuredImage = article.sources.find(
    (source) => !isWatermarked(source.source.link),
  )?.source.image_url || placeholderThumbnail;

  const getIcon = (category) => {
    const iconMap = {
      business: { icon: <WorkOutlineIcon sx={{ fontSize: '15px', color: 'text.secondary' }} />, text: 'Business' },
      economics: { icon: <PaidOutlinedIcon sx={{ fontSize: '15px', color: 'text.secondary' }} />, text: 'Economics' },
      geopolitics: { icon: <PublicIcon sx={{ fontSize: '15px', color: 'text.secondary' }} />, text: 'Geopolitics' },
      politics: { icon: <FlagCircleOutlinedIcon sx={{ fontSize: '15px', color: 'text.secondary' }} />, text: 'Politics' },
      tech_science: { icon: <ScienceOutlinedIcon sx={{ fontSize: '15px', color: 'text.secondary' }} />, text: 'Science & Tech' },
    };
    const { icon, text } = iconMap[category.toLowerCase()] || { icon: <WorkOutlineIcon />, text: 'Unknown' };
    return (
      <Stack direction="row" spacing={1} alignItems="center">
        {icon}
        <Typography variant="subtitle2" color="text.secondary">{text}</Typography>
      </Stack>
    );
  };

  const getCardWidth = () => {
    if (xsScreen) return '100%';
    if (isL) return 800;
    if (isM) return 600;
    return 500;
  };

  return (
    <Card sx={{ width: getCardWidth() }}>
      <CardActionArea component={Link} to={`/article?a=${article.id}`}>
        <CardMedia
          alt="article-image"
          image={featuredImage}
          sx={{
            height: 0,
            paddingTop: '45%',
            backgroundPosition: 'center',
            cursor: 'pointer',
            objectFit: 'contain',
          }}
        />
        <CardContent>
          <Tooltip title={article.title} arrow>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{
                overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical',
              }}
            >
              {article.title}
            </Typography>
          </Tooltip>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            divider={<Divider flexItem orientation="vertical" sx={{ my: 2 }} />}
            sx={{ pb: 1, pt: 1 }}
          >
            {getIcon(article.pnyx_category)}
            <Typography variant="subtitle2" color="text.secondary">{FormatTime.dateToElapsedTime(article.created_at)}</Typography>
          </Stack>
          <Stack sx={{ mt: 1 }}>
            {summary && summary.map((item) => (
              <div key={item} style={{ display: 'flex', alignItems: 'flex-start' }}>
                <CircleOutlinedIcon sx={{ fontSize: '10px', marginTop: '7px', marginRight: 2 }} />
                <Typography variant="body2" color="textSecondary">
                  {item}
                </Typography>
              </div>
            ))}
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

ArticleCard.propTypes = {
  article: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    pnyx_category: PropTypes.string.isRequired,
    sources: PropTypes.arrayOf(
      PropTypes.shape({
        source: PropTypes.shape({
          title: PropTypes.string.isRequired,
          image_url: PropTypes.string.isRequired,
          link: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    ).isRequired,
  }).isRequired,
};

export default ArticleCard;
