// React
import React, { useState } from 'react';

// External Components
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';

// Files
import { ReactComponent as NameIconSvg } from '../../../assets/name-icon.svg';
import { ReactComponent as IconSvg } from '../../../assets/icon.svg';

/* eslint-disable max-len */

function BlogHeader() {
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  const menuClickOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const menuClickClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'row', alignItems: 'center', px: { xs: 2, sm: 3 }, py: 3,
    }}
    >
      <Box component={Link} to="/" sx={{ display: { xs: 'flex', sm: 'none' } }}>
        <IconSvg style={{ cursor: 'pointer' }} />
      </Box>
      <Box component={Link} to="/" sx={{ display: { xs: 'none', sm: 'flex' } }}>
        <NameIconSvg style={{ cursor: 'pointer' }} />
      </Box>
      <Box sx={{ my: 'auto', ml: 'auto', display: { xs: 'flex', sm: 'none' } }}>
        <Stack direction="row">
          <IconButton size="large" onClick={menuClickOpen}>
            <MenuIcon fontSize="inherit" />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={menuOpen}
            onClose={menuClickClose}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          >
            <MenuItem component={Link} to="/about">
              <InfoOutlinedIcon sx={{ mr: 2 }} />
              <Typography>About</Typography>
            </MenuItem>
            <MenuItem component={Link} to="/contact">
              <EmailOutlinedIcon sx={{ mr: 2 }} />
              <Typography>Contact</Typography>
            </MenuItem>
            <MenuItem component={Link} to="/feed">
              <AutoAwesomeOutlinedIcon sx={{ mr: 2 }} />
              <Typography> AI Powered News</Typography>
            </MenuItem>
          </Menu>
        </Stack>
      </Box>
      <Box sx={{ my: 'auto', ml: 'auto', display: { xs: 'none', sm: 'flex' } }}>
        <Stack direction="row" spacing={4}>
          <Button color="secondary" component={Link} to="/about" sx={{ textTransform: 'none' }}>
            About
          </Button>
          <Button color="secondary" component={Link} to="/contact" sx={{ textTransform: 'none' }}>
            Contact
          </Button>
          <Button variant="contained" color="secondary" component={Link} to="/feed" startIcon={<AutoAwesomeOutlinedIcon />} sx={{ textTransform: 'none' }}>
            AI Powered News
          </Button>
        </Stack>
      </Box>
    </Box>
  );
}

export default BlogHeader;
