// React
import React from 'react';

// External Components
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';

function NotFound() {
  return (
    <Stack alignItems="center" spacing={2} sx={{ m: 'auto', pt: 10 }}>
      <RocketLaunchIcon sx={{ fontSize: '100px' }} />
      <Typography>404 Page Not Found</Typography>
    </Stack>
  );
}

export default NotFound;
