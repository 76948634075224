import React from 'react';
import { List, ListItem, Stack } from '@mui/material';
import XIcon from '@mui/icons-material/X';
import { FaDiscord } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { discord, x } from '../../utils/SocialLinks';

function SocialSection() {
  return (
    <List>
      <ListItem disablePadding>
        <Stack alignItems="flex-start" direction="row" spacing={2}>
          <Link to={x} target="_blank" rel="noopener noreferrer" underline="none">
            <XIcon sx={{
              height: '20px', width: '20px', cursor: 'pointer', color: 'text.hint',
            }}
            />
          </Link>
          <Link to={discord} target="_blank" rel="noopener noreferrer" underline="none">
            <FaDiscord style={{
              height: '20px', width: '20px', cursor: 'pointer', color: 'rgba(255, 255, 255, 0.5)',
            }}
            />
          </Link>
        </Stack>
      </ListItem>
    </List>
  );
}

export default SocialSection;
