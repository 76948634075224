import React from 'react';
import PropTypes from 'prop-types';

function CustomPagination({ pageCount, activeIndex, onPageChange }) {
  const renderDots = () => {
    const dots = [];

    // Determine the number of dots to render (maximum of 5)
    const numDots = Math.min(pageCount, 5);

    const handlePageChange = (index) => {
      if (index < 0) {
        onPageChange(pageCount - 1); // Loop to the last dot if index is negative
      } else if (index >= pageCount) {
        onPageChange(0); // Loop to the first dot if index exceeds pageCount
      } else {
        onPageChange(index);
      }
    };

    const handleKeyDown = (event, index) => {
      if (event.key === 'Enter') {
        handlePageChange(index);
      }
    };

    // Add dots to the array
    for (let i = 0; i < numDots; i += 1) {
      dots.push(
        <div
          key={i}
          role="button"
          tabIndex={0}
          aria-label={`Go to slide ${i + 1}`}
          onClick={() => handlePageChange(i)}
          onKeyDown={(event) => handleKeyDown(event, i)}
          style={{
            width: '8px',
            height: '8px',
            borderRadius: '50%',
            backgroundColor: activeIndex === i ? 'white' : 'rgba(255, 255, 255, 0.5)',
            marginRight: '8px',
            cursor: 'pointer',
          }}
        />,
      );
    }

    return dots;
  };

  return <div style={{ display: 'flex', justifyContent: 'center' }} aria-label="Slide controls">{renderDots()}</div>;
}

CustomPagination.propTypes = {
  pageCount: PropTypes.number.isRequired,
  activeIndex: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default CustomPagination;
