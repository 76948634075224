// React
import React from 'react';

// External Components
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  LabelList,
  Legend,
  Pie,
  PieChart,
  ReferenceLine,
  XAxis,
  YAxis,
} from 'recharts';
import isXsScreen from '../../common/utils/isXsScreen';
import isLScreen from '../../common/utils/isLScreen';
import isMScreen from '../../common/utils/isMScreen';
import isSScreen from '../../common/utils/isSScreen';
import JoinBetaButton from '../../common/components/JoinBetaButton';

/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unknown-property */

function LinebreakTick({
  x, y, textAnchor, verticalAnchor, payload, style,
}) {
  const text = payload.value;
  const lines = text.split('\n');
  const lineElements = lines.map((line, index) => (
    <text
      x={0}
      y={12 + index * 20}
      {...style}
      textAnchor={textAnchor}
      verticalAnchor={verticalAnchor}
    >
      {line}
    </text>
  ));
  return (
    <g transform={`translate(${x},${y})`}>
      {lineElements}
    </g>
  );
}

function LinebreakLabel({
  viewBox, dx, dy, style, value,
}) {
  const { x, y, height } = viewBox;
  const text = value;
  const lines = text.split('\n');
  const lineElements = lines.map((line, index) => (
    <text
      x={0}
      y={index * 20}
      {...style}
      textAnchor="middle"
      verticalAnchor="middle"
    >
      {line}
    </text>
  ));
  return (
    <g transform={`translate(${x + dx},${y + height / 2 + dy})`}>
      {lineElements}
    </g>
  );
}

function LegendStack({ payload }) {
  const barChartTextStyle = { fontSize: '1rem', fontWeight: 'bold', fill: 'rgba(255, 255, 255, 0.8)' };
  const barChartLabelStyle = { ...barChartTextStyle, fontSize: '1.25rem' };
  return (
    <Stack
      alignItems="center"
      spacing={2}
      sx={{ pb: 3 }}
    >
      <Typography variant="h6" color={barChartTextStyle.fill} sx={{ ...barChartLabelStyle, fontStyle: 'italic' }}>% who say news organizations do a good or bad job at ...</Typography>
      <Stack
        justifyContent="center"
        alignItems="center"
        direction="row"
        spacing={2}
        alignSelf="center"
      >
        {payload.map((entry) => (
          <Stack key={entry.id} direction="row" spacing={1} alignItems="center">
            <Box bgcolor={entry.color} width={20} height={20} />
            <Typography variant="h6" color={entry.color}>{entry.value}</Typography>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}

function About() {
  const isXs = isXsScreen();
  const isL = isLScreen();
  const isM = isMScreen();
  const isS = isSScreen();

  const barChartTextStyle = { fontSize: '1rem', fontWeight: 'bold', fill: 'rgba(255, 255, 255, 0.8)' };
  const barChartLabelStyle = { ...barChartTextStyle, fontSize: '1.25rem' };

  const data = [
    {
      category: "Covering what's\nimportant",
      journalists: 67,
      journalists2: -19,
      adults: 41,
      adults2: -36,
    },
    {
      category: 'Reporting accurately',
      journalists: 65,
      journalists2: -22,
      adults: 35,
      adults2: -43,
    },
    {
      category: 'Serving as a watchdog',
      journalists: 46,
      journalists2: -34,
      adults: 24,
      adults2: -44,
    },
    {
      category: 'Giving voice to\nunderrepresented',
      journalists: 46,
      journalists2: -35,
      adults: 24,
      adults2: -45,
    },
    {
      category: 'Correcting\nmisinformation',
      journalists: 43,
      journalists2: -40,
      adults: 25,
      adults2: -51,
    },
  ];

  return (
    <Stack
      alignItems="center"
      spacing={isXs ? 2 : 6}
      divider={<Divider flexItem />}
      sx={{ mx: isXs ? 1 : 'auto' }}
    >
      <Box
        sx={{ px: isL ? 24 : isXs ? 6 : 12, minHeight: 'calc(100vh - 176px)', width: '100%' }}
      >
        <Typography
          variant={isXs ? 'h5' : 'h4'}
          color="secondary.main"
          letterSpacing={4}
          fontWeight="bold"
          sx={{ textTransform: 'uppercase', pb: 4 }}
        >
          Our mission
        </Typography>
        <Typography variant={isXs ? 'h4' : 'h2'} fontWeight="bold">Make news media journalism again</Typography>
        <Typography
          variant={isXs ? 'h5' : 'h4'}
          color="secondary.main"
          letterSpacing={4}
          fontWeight="bold"
          sx={{ textTransform: 'uppercase', pt: 8 }}
        >
          The state of news media
        </Typography>
        <Stack
          direction={isXs ? 'column' : 'row'}
          justifyContent={isXs ? 'center' : 'space-between'}
          alignItems="center"
          spacing={6}
          sx={{ pr: 1, mt: isS ? 2 : -4 }}
        >
          <Box sx={{ maxWidth: 830 }}>
            <Typography variant={isXs ? 'h6' : 'h4'} fontWeight="bold" color="text.hint">Record levels of mistrust</Typography>
            <Typography variant={isXs ? 'h6' : isM ? 'h4' : 'h5'} color="text.hint" sx={{ mt: 2 }}>Over 70% of Americans say they have little trust in mass media to report news fully, accurately and fairly</Typography>
          </Box>
          <Box
            sx={{
              position: 'relative',
              display: 'inline-flex',
            }}
          >
            <PieChart width={260} height={260}>
              <Pie
                data={[{ value: 100 }]}
                startAngle={90}
                endAngle={-162}
                cx="50%"
                cy="50%"
                innerRadius={80}
                outerRadius={130}
                fill="#f8c185"
                stroke={false}
              />
            </PieChart>
            <Typography
              variant="h3"
              color="secondary.main"
              fontWeight="bold"
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                textAlign: 'center',
                zIndex: 1,
              }}
            >
              70%
            </Typography>
          </Box>
        </Stack>
        <Stack
          direction={isXs ? 'column-reverse' : 'row'}
          justifyContent={isXs ? 'center' : 'space-between'}
          alignItems="center"
          spacing={6}
          sx={{ mt: 6 }}
        >
          <Box
            sx={{
              position: 'relative',
              ...(isXs ? { alignSelf: 'center' } : { width: '100%' }),
            }}
          >
            <BarChart
              width={isXs ? 300 : isS ? 350 : 600}
              height={isXs ? 150 : 300}
              data={[{ width: 44 }, { width: 76, fill: '#ff8f85' }]}
              layout="vertical"
            >
              <XAxis type="number" domain={[0, 100]} hide />
              <YAxis dataKey="width" type="category" tick={false} />
              <Bar dataKey="width" fill="#f8c185" background={{ fill: 'none', stroke: 'rgba(255, 255, 255, 0.6)', strokeWidth: 2 }} />
            </BarChart>
            <Typography
              variant="h3"
              color="secondary.main"
              fontWeight="bold"
              sx={{
                position: 'absolute',
                top: isXs ? '8%' : '18%',
                left: '-42px',
              }}
            >
              44%
            </Typography>
            <Typography
              variant="h3"
              color="#ff8f85"
              fontWeight="bold"
              sx={{
                position: 'absolute',
                bottom: isXs ? '8%' : '18%',
                left: '-42px',
              }}
            >
              76%
            </Typography>
          </Box>
          <Box sx={{ maxWidth: 830 }}>
            <Typography variant={isXs ? 'h6' : 'h4'} fontWeight="bold" color="text.hint">Media isn&apos;t serving the public</Typography>
            <Typography variant={isXs ? 'h6' : isM ? 'h4' : 'h5'} color="text.hint" sx={{ mt: 2 }}>Only 44% of journalists think they should always strive to give every side equal coverage compared to 76% of U.S. adults</Typography>
          </Box>
        </Stack>
      </Box>

      <Stack
        justifyContent="center"
        sx={{
          px: isXs ? 6 : 12,
          minHeight: 'calc(100vh - 32px)',
          width: '100%',
        }}
      >
        <Typography variant={isXs ? 'h5' : 'h3'} fontWeight="bold">Journalists don&apos;t understand the problem</Typography>
        <Typography variant={isXs ? 'h6' : 'h4'} color="text.hint" sx={{ mt: 2 }}>Journalists and the American public have starkly different views on how well the media performrs its core functions.</Typography>
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ mt: 8, pl: isXs ? 0 : isL ? 8 : 16, width: '100%' }}
        >
          <Box
            height={isXs ? 450 : 650}
            sx={{
              maxWidth: '100vw',
              ...(isS && { width: '85vw' }),
              overflowX: isS ? 'auto' : 'visible',
              maxHeight: '100vh',
              pl: isXs ? 20 : isS ? 16 : 0,
            }}
          >
            <BarChart
              width={isL ? 1200 : 1000}
              height={isXs ? 400 : 600}
              stackOffset="sign"
              overflow="visible"
              data={data}
            >
              <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
              <XAxis dataKey="category" tick={LinebreakTick} style={barChartTextStyle} />
              <YAxis
                type="number"
                domain={[-100, 100]}
                style={{ ...barChartTextStyle, fontSize: '1.25rem', fontWeight: 'bold' }}
                tickFormatter={Math.abs}
              >
                <Label
                  value={'Very/somewhat\nbad'}
                  position="left"
                  style={barChartLabelStyle}
                  content={LinebreakLabel}
                  dx={-60}
                  dy={isXs ? 60 : 100}
                />
                <Label
                  value={'Very/somewhat\ngood'}
                  position="left"
                  style={barChartLabelStyle}
                  content={LinebreakLabel}
                  dx={-60}
                  dy={isXs ? -70 : -115}
                />
              </YAxis>
              <ReferenceLine y={0} stroke="#fff" />
              <Legend
                verticalAlign="top"
                align="center"
                payload={[
                  {
                    value: 'U.S. Journalists',
                    type: 'square',
                    id: 'j',
                    color: '#f8c185',
                    style: { paddingTop: '1rem', paddingBottom: '1rem' },
                  },
                  {
                    value: 'U.S. Adults',
                    type: 'square',
                    id: 'a',
                    color: '#ff7b6f',
                    style: { paddingTop: '1rem', paddingBottom: '1rem' },
                  },
                ]}
                content={LegendStack}
              />
              <Bar dataKey="journalists" fill="#fab569" stackId="j">
                <LabelList dataKey="journalists" position="inside" style={barChartLabelStyle} />
              </Bar>
              <Bar dataKey="adults" fill="#ff7b6f" stackId="a">
                <LabelList dataKey="adults" position="inside" style={barChartLabelStyle} />
              </Bar>
              <Bar dataKey="journalists2" fill="#f8c185" stackId="j">
                <LabelList dataKey="journalists2" position="inside" style={barChartLabelStyle} formatter={Math.abs} />
              </Bar>
              <Bar dataKey="adults2" fill="#ff8f85" stackId="a">
                <LabelList dataKey="adults2" position="inside" style={barChartLabelStyle} formatter={Math.abs} />
              </Bar>
            </BarChart>
          </Box>
        </Stack>
      </Stack>

      <Stack
        spacing={4}
        alignItems="center"
        justifyContent="center"
        sx={{ py: 4 }}
      >
        <Typography variant={isXs ? 'h4' : 'h2'} fontWeight="bold" color="white">Something must be done!</Typography>
        <JoinBetaButton />
      </Stack>
    </Stack>
  );
}

export default About;
