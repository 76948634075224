export const x = 'https://x.com/pnyx_news';
export const discord = 'https://discord.gg/FxnSfRfS7b';

// Not posting to yet so do not include
export const apple = 'https://podcasts.apple.com/us/podcast/pnyx/id1722016809';
export const spotify = 'https://open.spotify.com/show/6HkmnsryeIYIKAW2FHC84k';
export const youtube = 'https://www.youtube.com/@pnyx_news';
export const insta = 'https://www.linkedin.com/company/pnyx-inc';
export const linkedIn = 'https://www.linkedin.com/company/pnyx-inc';
export const tiktok = 'https://www.tiktok.com/@the_pnyx_';
