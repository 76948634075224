import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ReactComponent as NameIconSvg } from '../../../assets/name-icon.svg';

function LogoSection({ toggleDrawer }) {
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '70px',
          marginTop: 1,
          marginBottom: 1,
        }}
      >
        <IconButton sx={{ mr: '5px', mt: '5px' }} onClick={toggleDrawer}>
          <ArrowBackIcon />
        </IconButton>
        <Box component={Link} to="/" sx={{ display: 'flex', alignItems: 'center' }}>
          <NameIconSvg style={{ cursor: 'pointer', marginLeft: '8px' }} />
        </Box>
      </Box>
    </Box>

  );
}

LogoSection.propTypes = {
  toggleDrawer: PropTypes.func.isRequired,
};

export default LogoSection;
