import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';

import FormatTime from '../../../common/utils/FormatTime';

function ArticleDetails({ title, pubDate }) {
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleShareClick = () => {
    // Copy current link to clipboard
    navigator.clipboard.writeText(window.location.href);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ my: 2 }}>
      <Typography
        variant="h5"
        fontWeight="bold"
        sx={{
          overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical',
        }}
      >
        {title}
      </Typography>
      <Box sx={{
        display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 2,
      }}
      >
        <Chip
          clickable
          variant="outlined"
          label="Share"
          size="small"
          icon={<ShareOutlinedIcon />}
          sx={{ mr: 1 }}
          onClick={handleShareClick}
        />
        <Typography variant="subtitle2" color="text.secondary">{FormatTime.dateToElapsedTime(pubDate)}</Typography>
      </Box>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message="Link copied to clipboard"
        action={(
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
      />
    </Box>
  );
}

ArticleDetails.propTypes = {
  title: PropTypes.string.isRequired,
  pubDate: PropTypes.string.isRequired,
};

export default ArticleDetails;
