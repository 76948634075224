import React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import PropTypes from 'prop-types';

/* eslint-disable react/no-array-index-key */

function LegacyArticleRender({ content }) {
  return (
    <Typography sx={{ mt: 2, mb: 3 }}>
      {content.split('\n').map((line) => (
        <React.Fragment key={line}>
          {line}
          <br />
        </React.Fragment>
      ))}
    </Typography>
  );
}

function NewArticleRender({ content }) {
  const sections = content.split(/\n\n|\r\n\r\n/);
  const keyDetailsSection = sections[1].replace('Key Details:', '');
  const keyDetailsPoints = keyDetailsSection.split('\n-').map((point) => point.trim()).filter((point) => point !== '');

  return (
    <Stack spacing={3} sx={{ mt: 2, mb: 3 }}>
      <div>
        <Typography variant="h6" fontWeight="bold">Introduction</Typography>
        <Typography>{sections[0]}</Typography>
      </div>
      <div>
        <Typography variant="h6" fontWeight="bold">Key Details</Typography>
        <Stack spacing={1}>
          {keyDetailsPoints.map((point, index) => (
            <div key={index} style={{ display: 'flex', alignItems: 'flex-start' }}>
              <CircleOutlinedIcon sx={{ fontSize: '10px', marginTop: '7px', marginRight: 2 }} />
              <Typography>
                {point}
              </Typography>
            </div>
          ))}
        </Stack>
      </div>
      <div>
        <Typography variant="h6" fontWeight="bold">Conclusion</Typography>
        <Typography>{sections[2]}</Typography>
      </div>
    </Stack>
  );
}

function ArticleBody({ content, createdAt }) {
  const createdDate = new Date(createdAt);
  const cuttOffDate = new Date('2024-04-15 10:21:32.067645+00');
  const cuttOffDateFuture = new Date('2024-04-19 05:02:46.529336+00'); // Capitalize 'Date'

  const isNewFormat = createdDate >= cuttOffDate && createdDate <= cuttOffDateFuture;

  return (
    <div>
      {isNewFormat ? (
        <NewArticleRender content={content} />
      ) : (
        <LegacyArticleRender content={content} />
      )}
    </div>
  );
}

LegacyArticleRender.propTypes = {
  content: PropTypes.string.isRequired,
};

NewArticleRender.propTypes = {
  content: PropTypes.string.isRequired,
};

ArticleBody.propTypes = {
  content: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
};

export default ArticleBody;
